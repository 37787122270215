import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SwiperModule } from 'swiper/angular';
import { SliderComponent } from './slider/slider.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BannerComponent } from './banner/banner.component';
import { ListingItemComponent } from './listing-item/listing-item.component';
import { ImageComponent } from './image/image.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ListingBannerComponent } from './listing-banner/listing-banner.component';
import { PoliciesComponent } from './policies/policies.component';
import { GoogleFormComponent } from './google-form/google-form.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {MatDialogModule} from '@angular/material/dialog';
import { RebillyFormComponent } from './rebilly-form/rebilly-form.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { SearchComponent } from './search/search.component';
import { PopupComponent } from './popup/popup.component';
import {MatRadioModule} from '@angular/material/radio';
import { LandingPagesComponent } from './landing-pages/landing-pages.component';
import { BigTitleWithSeperatorComponent } from './big-title-with-seperator/big-title-with-seperator.component';
import { ListingPageComponent } from './listing-page/listing-page.component';
import { ItemDetailsPageComponent } from './item-details-page/item-details-page.component';
import { RouterModule } from '@angular/router';
import { NgAisModule, NgAisInstantSearch } from 'angular-instantsearch';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { AgmCoreModule } from '@agm/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CustomRefineComponent } from './custom-refine/custom-refine.component';
import { CustomClearComponent } from './custom-clear/custom-clear.component';
import { CustomLoadComponent } from './custom-load/custom-load.component';
import { NumbersFormatPipe } from './numbers-format.pipe';
import { ThousandFormatPipe } from './thousand-format.pipe';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { QuillModule } from 'ngx-quill'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


@NgModule({
  declarations: [
    SliderComponent,
    BannerComponent,
    ListingItemComponent,
    ImageComponent,
    ListingBannerComponent,
    PoliciesComponent,
    GoogleFormComponent,
    ProgressSpinnerComponent,
    RebillyFormComponent,
    SearchComponent,
    PopupComponent,
    LandingPagesComponent,
    BigTitleWithSeperatorComponent,
    ListingPageComponent,
    ItemDetailsPageComponent,
    EmptyStateComponent,
    CustomRefineComponent,
    CustomClearComponent,
    CustomLoadComponent,
    NumbersFormatPipe,
    ThousandFormatPipe,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    QuillModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC9GC8ygufXZNIzBJV1Kb-uyQ-MrgsaGew',
      libraries: ['places']
    }),
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot(),
    NgAisModule.forRoot(),
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    ShareIconsModule,
    RouterModule,
    SwiperModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatIconModule,
    ShareModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    MatDialogModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    AgmCoreModule,
    ReactiveFormsModule,
    NgAisModule,
    MatSelectModule,
    MatInputModule,
    SwiperModule,
    BannerComponent,
    SliderComponent,
    RouterModule,
    MatRadioModule,
    CustomLoadComponent,
    MatFormFieldModule,
    MatDatepickerModule,
    CustomRefineComponent,
    ThousandFormatPipe,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    QuillModule,
    ShareIconsModule,
    MatToolbarModule,
    ShareModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ListingItemComponent,
    NumbersFormatPipe,
    MatCheckboxModule,
    ImageComponent,
    ListingBannerComponent,
    CustomClearComponent,
    MatProgressBarModule,
    MatSnackBarModule,
    PoliciesComponent,
    GoogleFormComponent,
    ProgressSpinnerComponent,
    EmptyStateComponent,
    NgCircleProgressModule,
    MatDialogModule,
    RebillyFormComponent,
    SearchComponent,
    PopupComponent,
    LandingPagesComponent,
    BigTitleWithSeperatorComponent,
    ListingPageComponent,
    ItemDetailsPageComponent
  ],
  providers: [NgAisInstantSearch],
})
export class SharedModule { }
