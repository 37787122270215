import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  @Input() searchArray:any = [
    {
      searchValue:''
    }
  ];
  @Input() containerWidth:string;
  @Input() justifyContent:string;
  @Input() inPage:string;
  localAppService: AppService;
  sidenav:MatSidenav;
  openFilter: boolean;

  constructor(private router: Router, private appService: AppService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.localAppService= this.appService;
    console.log(this.containerWidth)
  }

  navigateTo(link){
    this.router.navigate([link])
  }

  quickFilterClick(){
    this.snackBar.open('Search will be active soon! :)', '×', { panelClass: 'success', verticalPosition: 'top', duration: 6000 });       
  }

 

}
