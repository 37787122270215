<ais-instantsearch [config]="config"*ngIf="hasSearch && sliderData && sliderData.length > 0">
    <ais-configure
    [searchParameters]="algoliaSearchParam"
    ></ais-configure>
    <ais-search-box [ngStyle]="{'display':'none'}"></ais-search-box>
    <div *ngIf="sliderTitle" class="default-padding centered-container">
        <span class="slider-title p-t-60" [ngStyle]="{'justify-content': (buttonLabel && !subtitle) ? 'space-between' : titleAlign}" *ngIf="sliderTitle">{{sliderTitle}}
            <div class="flex-container search-button-container">
                <div class="flex-container search-container" [ngStyle]="{'justify-content': 'flex-end'}" *ngIf="hasSearch &&  searchArray && searchArray.length > 0">
                    <div class="input-search" *ngFor="let elt of searchArray ; let i = index" [ngClass]="{'search-input-type': elt?.type == 'search'}" [ngStyle]="{'width': elt.width, 'margin-left': i != 0 ? '15px' : '0', 'margin-right':  i == searchArray.length - 1 ? '20px' : '0', padding: '0 10px'}">
                        <ais-search-box class="input" [autofocus]="true" [searchAsYouType]="true" autocomplete="off"  *ngIf="elt.type == 'search'"
                        (keyup)="searchIfEnter($event)" [placeholder]="elt.placeholder"></ais-search-box>
                        <mat-select disableOptionCentering panelClass="panelClass" [(ngModel)]="elt.selectValue" [ngModelOptions]="{standalone: true}" *ngIf="elt?.type == 'select'">
                            <mat-option *ngFor="let opt of elt.options" [value]="opt">
                                {{opt}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>        
                <button class="liveLoveBtn" type="button" (click)="navigateTo(buttonLink)" *ngIf="buttonLabel && !subtitle">{{buttonLabel}}</button>
            </div>
        </span>
        <span class="subtitle" *ngIf="subtitle">{{subtitle}}
            <button class="liveLoveBtn" type="button" (click)="navigateTo(buttonLink)" *ngIf="buttonLabel">{{buttonLabel}}</button>
        </span>
    </div>
    
    <div class="swiper-container {{sliderId}} centered-container p-l-20" >
        <div class="arrows-container" *ngIf="!localAppService?.mobile">
            <div class="arrow-prev" (click)="swiperPrev()" >
                <img src="https://storage.googleapis.com/livelove-dev.appspot.com/slider-prev.png" alt="left-arrow">
            </div>
            <div class="arrow-next" (click)="swiperNext()" >
                <img src="https://storage.googleapis.com/livelove-dev.appspot.com/slider-next.png" alt="right-arrow">
            </div>
        </div>
        
    <div class="swiper-wrapper" [id]="sliderId" >
            <div class="swiper-slide" *ngFor="let elt of sliderData" [ngStyle]="{'width': elt.width, 'margin-right': '20px'}">
                <app-listing-item [data]="elt" 
                    [id]="collection ? collection : null"
                    [boxShadow]="boxShadow"
                    [sliderClass]="sliderId">
                </app-listing-item>
            </div>
        </div>
    </div>
</ais-instantsearch>



<ng-container *ngIf="!hasSearch" >
    <div *ngIf="sliderTitle" class="default-padding centered-container">
        <span class="slider-title p-t-60" [ngStyle]="{'justify-content': (buttonLabel && !subtitle) ? 'space-between' : titleAlign}" *ngIf="sliderTitle">{{sliderTitle}}
            <div class="flex-container search-button-container">
                <button class="liveLoveBtn" type="button" (click)="navigateTo(buttonLink)" *ngIf="buttonLabel && !subtitle">{{buttonLabel}}</button>
            </div>
        </span>
        <span class="subtitle" *ngIf="subtitle">{{subtitle}}
            <button class="liveLoveBtn" type="button" (click)="navigateTo(buttonLink)" *ngIf="buttonLabel">{{buttonLabel}}</button>
        </span>
    </div>
    <div class="centered-container"
        *ngIf="sliderData && sliderData.length == 0 && emptyState && sliderId != 'instagram-slider'">
        <app-empty-state [text]="emptyState.text" [link]="emptyState.link"></app-empty-state>
    </div>
    
    <div class="swiper-container {{sliderId}} p-l-20 centered-container" *ngIf="sliderId!= 'instagram-slider' && sliderData && sliderData.length > 0">
        <div class="arrows-container" *ngIf="!localAppService?.mobile">
            <div class="arrow-prev" (click)="swiperPrev()" >
                <img src="https://storage.googleapis.com/livelove-dev.appspot.com/slider-prev.png" alt="left-arrow">
            </div>
            <div class="arrow-next" (click)="swiperNext()" >
                <img src="https://storage.googleapis.com/livelove-dev.appspot.com/slider-next.png" alt="right-arrow">
            </div>
        </div>
        
        <div class="swiper-wrapper" [id]="sliderId" >
            <div class="swiper-slide" *ngFor="let elt of sliderData" [ngStyle]="{'width': elt.width, 'margin-right': '20px'}">
                {{elt?.collection}}
                <app-listing-item [data]="elt" 
                    [id]="collection ? collection : null"
                    [link]="buttonLink"
                    [boxShadow]="boxShadow"
                    [sliderClass]="sliderId">
                </app-listing-item>
            </div>
        </div>
    </div>

    <div class="p-l-20 centered-container" *ngIf="sliderId == 'instagram-slider'">
        <div [ngStyle]="{'margin-top': '30px'}" id="curator-feed-default-feed-layout"></div>
    </div>
</ng-container>
