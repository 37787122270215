import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class DataCallService {
  constructor(private http: HttpClient, private appService: AppService) {}

  getAddressFromLatLng(latitude, longitude) {
    let params = new HttpParams();
    params = params.set('key', 'AIzaSyC9GC8ygufXZNIzBJV1Kb-uyQ-MrgsaGew');
    params = params.set('latlng', latitude + ',' + longitude);
    return this.http
      .get<any>('https://maps.googleapis.com/maps/api/geocode/json', {
        params: params,
      })
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  subscribeToMailchimp(email) {
    const API_KEY = '4f88b830464e45af03b0ff36b1a31530-us6';
    const AUDIENCE_ID = this.appService.country.audienceID;
    console.log(AUDIENCE_ID, 'idddddddd');
    const SEND_WELCOME = true;
    // subscriber details
    const SUBSCRIBER_EMAIL = email;

    const URL =
      'https://us6.api.mailchimp.com/2.0/lists/subscribe.json?apikey=' +
      API_KEY +
      '&id=' +
      AUDIENCE_ID +
      '&email[email]=' +
      SUBSCRIBER_EMAIL +
      '&double_optin=false&send_welcome=' +
      SEND_WELCOME;
    // call HTTP get request
    return this.http.get(URL).pipe(
      map((data) => {
        console.log(data);
        return data;
      }),
      catchError((e) => {
        return e;
      })
    );
  }

  getLatLngFromAddress(address) {
    let params = new HttpParams();
    params = params.set('key', 'AIzaSyBzyzg5h1rnHHnsyVc6LtsUuR0rIAIqb6E');
    params = params.set('address', address);
    return this.http
      .get<any>('https://maps.googleapis.com/maps/api/geocode/json', {
        params: params,
      })
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }
}
