import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-details-page',
  templateUrl: './item-details-page.component.html',
  styleUrls: ['./item-details-page.component.less']
})
export class ItemDetailsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
