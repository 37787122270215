import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-listing-item',
  templateUrl: './listing-item.component.html',
  styleUrls: ['./listing-item.component.less'],
})
export class ListingItemComponent implements OnInit {
  @Input() data: any;
  @Input() buttonLabel: string;
  @Input() buttonLink: string;
  @Input() buttonClass: string;
  @Input() subdetailsColor: string;
  @Input() sliderClass: string;
  @Input() link: string;
  @Input() boxShadow: boolean = true;
  @Input() id: string;
  @Input() members: string;
  itemId: string = Math.random().toString().split('.')[1];
  progress: number = 0;
  resizeArray: any[] = [
    { id: 'fund-slider', resize: '_305x205' },
    { id: 'event-slider', resize: '_305x205' },
    { id: 'campaign-slider', resize: '_305x205' },
    { id: 'shop-slider', resize: '_305x274' },
    { id: 'community-slider', resize: '_305x305' },
    { id: 'logo-slider', resize: '' },
    { id: 'recruit-slider', resize: '_305x205' },
  ];
  resize: string;
  fundExpires: string;
  @Output() openNGOListing: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    console.log(this.data, this.id);
    if (this.resizeArray.filter((elt) => elt.id == this.id).length > 0) {
      this.resize = this.resizeArray.filter(
        (elt) => elt.id == this.id
      )[0].resize;
    }
    if (this.id == 'campaigns') {
      this.progress = (+this.data.donors / +this.data.goal) * 100;
    }
    if (this.data.date) {
      this.data.date = new Date(this.data.date).toDateString();
    }
    if (this.data.eventDate) {
      this.data.eventDate = new Date(this.data.eventDate).toDateString();
    }
    console.log(this.data.duration);
    if (
      this.id == 'funds' &&
      this.data.duration &&
      this.data.duration.toString().toLowerCase() != 'monthly'
    ) {
      let d = new Date(this.data.date);
      // use a combination of setDate and getDate +- the number of days
      // on the temporary date object, then asign it to our variable
      let expiry = d.setDate(d.getDate() + +this.data.duration);
      // turn our date variable into a new date, and convert it to an ISO date-time string
      this.fundExpires = new Date(expiry).toDateString();
      // see the result in the console
      console.log(this.fundExpires);
    }

    this.link =
      this.id == 'communities'
        ? this.data.link && this.data.link != ''
          ? this.data.link
          : '/communities/all'
        : this.id == 'campaigns'
        ? '/funds/campaigns'
        : this.id == 'funds'
        ? '/funds/all'
        : this.id == 'gigs'
        ? '/volunteers/gigs'
        : this.id == 'events'
        ? '/volunteers/events'
        : this.link;
    // console.log(this.data.link)
  }

  navigateTo(link = null, itemId = null, query = null) {
    console.log(link, itemId);
    if (window.location.pathname.indexOf('recruit') > -1) {
      this.appService.openPositionSection = true;
    }
    if (this.id != 'training') {
      console.log(link, this.data);
      if (
        link &&
        (link.indexOf('http') > -1 || link.indexOf('instagram') > -1)
      ) {
        if (link.indexOf('instagram') > -1) {
          window.open('https://' + link, '_blank');
        } else {
          window.open(link, '_blank');
        }
      } else if (link && itemId) {
        // console.log(link, itemId)
        this.router.navigate([link + '/' + itemId]);
      } else {
        this.router.navigate([link]);
      }

      // console.log(window.location.pathname)
      // this.router.navigate([link])
    }
  }

  openNgoListing(e, ngoId) {
    e.stopPropagation();
    console.log(ngoId);
    this.openNGOListing.emit(ngoId)
  }
  openForm() {
    this.appService.openForm();
  }
}
