import { Component, Inject, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectPagination } from 'instantsearch.js/es/connectors';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-custom-load',
  templateUrl: './custom-load.component.html',
  styleUrls: ['./custom-load.component.less']
})
export class CustomLoadComponent extends BaseWidget implements OnInit{
  public state: {
    pages: number[];
    currentRefinement: number;
    nbHits: number;
    nbPages: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    refine: Function;
    createURL: Function;
    widgetParams: Function;
  }
  @Input()loadMoreLabel:string;
  navigation:string[]=["", "", ""];
  localAppService:AppService;
  constructor(
    private appService: AppService,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) { 
    super('CustomLoadComponent');
  }

  ngOnInit(): void {
    this.localAppService = this.appService;
    this.createWidget(connectPagination, {
      // instance options
    });
    super.ngOnInit();
  }

  loadMore(){
    // this.appService.loadMoreListing = true;
    this.state.refine(this.state.currentRefinement + 1)
  }

}
