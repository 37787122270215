import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as algoliasearch from 'algoliasearch';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
const searchClient = algoliasearch(
  '8MA5GJR7QU',
  'ebbb5ecbfeaa76c44aa219b62a61a267'
);
@Component({
  selector: 'app-listing-page',
  templateUrl: './listing-page.component.html',
  styleUrls: ['./listing-page.component.less'],
})
export class ListingPageComponent implements OnInit {
  openFilter: boolean = false;
  @Input() quickFilters: any[] = null;
  @Input() searchArray: any[] = [];
  @Input() title: string;
  @Input() quickText: string;
  @Input() loadMoreBtn: string;
  @Input() indexName: string;
  @Input() id: string;
  @Input() sliderClass: string;
  config = {
    searchClient,
    routing: true,
    attributesForFaceting: [],
  };
  @Input() data: any[] = [];
  algoliaSearchParam: any = {
    query: '',
    filters: '',
    hitsPerPage: this.data.length > 20 ? this.data.length : 20,
  };

  navigation: string[] = ['', '', ''];
  url: string;
  localAppService: AppService;
  formId: string = '1tvV4LkeaorYZHOL4eZHfqIr3B7KeQBg_fUElzKPdOtk';
  formBannerContent: any = {
    background: '#1941D0',
    titleFont: '30px',
    showSeparator: true,
    title: 'Apply for the gig',
  };
  loadMoreLabel: string;
  productRenderer: any;
  transformItems: any;
  showPrevious: boolean = true;
  @Input() quickFilterAttribute;
  quickFilterValue: string;
  activeFilters: any[] = [];
  @Input() emptyState: any;
  isLoadMore: boolean = false;
  isFirstLoad: boolean = true;
  isLastPage: boolean;
  constructor(private appService: AppService, private router: Router) {}

  ngOnInit(): void {
    if (this.appService.country.value == 'global') {
      this.algoliaSearchParam.filters = 'status:approved';
    } else {
      this.algoliaSearchParam.filters =
        'status:approved AND country:' + this.appService.country.value;
    }

    if (this.id == 'communities' && this.sliderClass == 'recruit-slider') {
      this.algoliaSearchParam.filters += ' AND NOT openPositions:0';
    }
    console.log(this.quickFilters);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.loadMoreLabel = this.loadMoreBtn;
        this.config['indexName'] = this.indexName;
        this.url = window.location.pathname;
      }
    });
    this.loadMoreLabel = this.loadMoreBtn;
    this.config['indexName'] = this.indexName;
    console.log(this.config);
    this.localAppService = this.appService;
    this.url = window.location.pathname;
    console.log(this.url);
    this.productRenderer = (products) => {
      console.log(products, this.algoliaSearchParam);
      this.isLoadMore = false;
      return products;
    };
    this.transformItems = (custom) => {
      // console.log(custom)
      // alert('in renderer')
      this.activeFilters = custom;
      return this.activeFilters;
    };
  }

  filterByNGO(event){
    console.log(event)
    if(event){
      this.algoliaSearchParam.filters += ' AND userId:' + event;
    }
  };

  openlistingFilter() {
    this.openFilter = true;
    document.getElementById('sub-header').style.zIndex = '0';
    document.getElementById('main-header').style.zIndex = '0';
  }

  loadMore() {
    this.isLoadMore = true;
  }

  closeListingFilter() {
    this.openFilter = false;
    document.getElementById('sub-header').style.zIndex = '999';
    document.getElementById('main-header').style.zIndex = '999';
  }
}
