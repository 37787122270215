import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.less']
})
export class ImageComponent implements OnInit {
  @Input() url:string;
  @Input() width:string;
  @Input() position:string;
  @Input() isLogoImage:boolean= false;
  @Input() ratio: number;
  @Input() id: string;
  @Input() hasIcon:boolean = false;
  @Input() icon:string;
  @Input() text:string;
  ratioArray:any[]=[
    {id:"communities", ratio:1},
    {id:"grid", ratio:0.91},
    {id:"communities", ratio:1.65},
    {id:"training", ratio:1.48},
    {id:"campaigns", ratio:1.9},
    {id:"events", ratio:1.48},
    {id:"instagram-slider", ratio:1.48},
    {id:"funds", ratio:1.48},
    {id:"shop-slider", ratio:1.11},
    {id:"logo-slider", ratio:0.94}
  ];
  randomNumber: number = Math.random();
  localAppService: AppService;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    console.log(this.width)
    this.localAppService = this.appService;
    let type=this.ratioArray.filter(elt=>elt.id == this.id)
    if(type.length>0){
      this.ratio = this.ratioArray.filter(elt=>elt.id == this.id)[0].ratio;
    }else{
      this.ratio = this.ratio
    }
  }

  ngAfterViewInit(){
    setTimeout(
      ()=>{
        this.calculateImageHeight();
      },500
    )
  }

  calculateImageHeight(){
    // console.log(this.ratio)
    let image =  document.getElementById(this.url + '-' + this.randomNumber);
    if(image){
      if(this.ratio && this.ratio != null){
        image.style.height = (+(image.offsetWidth) / (this.ratio)).toString()  + 'px';
      }else{
        image.style.height = (+(image.offsetWidth) / (1.48)).toString()  + 'px';
      }
    }
  }

  @HostListener("window:resize", [])
    onResize(){
      this.calculateImageHeight();
    }

}
