<div class="empty-state-container">
    <div class="flex-container space-between">
        <div class="copy-container">
            <span class="text">{{text[0]}}</span>
            <span class="text" *ngIf="text[1]">{{text[1]}} <a class="link" *ngIf="link" [routerLink]="link">click here</a> </span>
        </div>
        <div class="button" (click)="navigateTo(link)" *ngIf="link">
            <img src="https://storage.googleapis.com/livelove-dev.appspot.com/empty-state-add.png" alt="empty-state">
        </div>
    </div>
</div>
