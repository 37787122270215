import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from './../../environments/environment.prod';
import { AppService } from './../services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Output() public sidenavToggle = new EventEmitter();
  @Input() whiteHeader: boolean;
  @Input() greenHeader: boolean;
  profileDropdown: any[] = [
    { label: 'View profile', link: '/profile' },
    { label: 'change password', link: '/profile/reset' },
    { label: 'logout', link: '/' },
  ];
  country: string;
  headerMenu: any[] = [];
  showHeaderOffset: boolean = false;
  showFiller = false;
  mobileMenu: boolean =
    document.documentElement.clientWidth <= 991 ? true : false;
  mobile: boolean = document.documentElement.clientWidth <= 768 ? true : false;
  subCategories: any;
  localAppService: AppService;
  url: string;
  firstScroll: boolean = true;
  previousUrl: any;
  localAuthService: AuthService;
  fullUrl: string;

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // console.log(this.url.indexOf(elt.link) > -1 && elt.link != '/')
    console.log(this.appService.country);
    this.authService.db
      .collection('static-content')
      .doc(this.appService.country.value)
      .collection('static-content')
      .doc('header')
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.headerMenu = doc.data().data;
          let currentPage = this.headerMenu.filter(
            (elt) => this.url.includes(elt.link) && elt.link != '/'
          );
          this.subCategories =
            currentPage && currentPage.length > 0
              ? currentPage[0].subCategory
              : null;
          this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
              this.url = window.location.pathname;
              this.fullUrl = window.location.href;
              let currentPage = this.headerMenu.filter(
                (elt) => this.url.includes(elt.link) && elt.link != '/'
              );
              this.subCategories =
                currentPage && currentPage.length > 0
                  ? currentPage[0].subCategory
                  : null;
              if (
                val.url != '/' &&
                val.url.indexOf('login') == -1 &&
                val.url.indexOf('register') == -1 &&
                val.url.indexOf('about') == -1 &&
                val.url.indexOf('profile') == -1 &&
                this.previousUrl != '/' &&
                this.previousUrl.indexOf('login') == -1 &&
                this.previousUrl.indexOf('register') == -1 &&
                this.previousUrl.indexOf('about') == -1 &&
                this.previousUrl.indexOf('profile') == -1 &&
                !this.localAppService.mobile
              ) {
                this.firstScroll = false;
                console.log('jmynhbgfvcdxsdcfvrgbthnyjumhnybtgvf');
                let interval = setInterval(() => {
                  window.scrollTo({ top: 80, behavior: 'smooth' });
                  if (document.documentElement.scrollTop > 0) {
                    clearInterval(interval);
                    document.getElementById('main-header').style.display =
                      'none';
                    document.getElementById('header-offset').style.height =
                      '80px';
                  }
                }, 100);
              } else {
                document.getElementById('main-header').style.display = 'unset';
              }
              console.log(val.url, this.previousUrl);
              this.previousUrl = val.url;
            }
          });
        }
      });
    this.localAuthService = this.authService;
    this.localAppService = this.appService;
    this.fullUrl = window.location.href;
    console.log(this.fullUrl.indexOf('/communities/all'));
    this.url = window.location.pathname;
    this.previousUrl = this.url;
    console.log(this.subCategories);
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
    if (
      document.getElementById('main-header').classList.contains('box-shadow')
    ) {
      document.getElementById('main-header').classList.remove('box-shadow');
    }
  }

  navigateProfile(event) {
    console.log(event.value);
    if (event.value.label == 'logout') {
      this.authService.logout();
    } else {
      this.router.navigate([event.value.link]);
    }
  }

  navigateTo(event) {
    if (event.value.link.indexOf('http') > -1) {
      window.open(event.value.link, '_blank');
    } else {
      this.router.navigate([event.value.link]);
    }
  }

  getSubCategories(subCategory) {
    if (subCategory) {
      this.subCategories = subCategory;
    }
  }

  // navigateTo(link) {
  //   if (link.indexOf('http') > -1) {
  //     window.open(link, '_blank');
  //   } else {
  //     this.router.navigate([link]);
  //   }
  // }

  switchCountry(event) {
    console.log(event.value);
    let country = this.appService.countries.filter(
      (elt) => elt.value == event.value
    )[0];
    localStorage.setItem('country', JSON.stringify(country));
    window.location.reload();
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.firstScroll = false;
    // alert(document.documentElement.scrollTop);
    if (document.documentElement.scrollTop >= 80) {
      this.showHeaderOffset = true;
      if (!this.appService.greenHeader) {
        this.appService.whiteHeader = true;
      }
      if (!this.appService.mobile && document.getElementById('sub-header')) {
        document.getElementById('main-header').style.display = 'none';
        document.getElementById('sub-header').classList.add('sticky');
        document.getElementById('header-offset').style.height = '80px';
      }
    } else {
      // console.log(document.documentElement.scrollTop)
      if (!this.appService.greenHeader && window.location.pathname == '/') {
        this.appService.whiteHeader = false;
      }
      this.showHeaderOffset = false;
      if (document.getElementById('sub-header')) {
        if (document.documentElement.scrollTop < 80 && !this.firstScroll) {
          document.getElementById('main-header').style.display = 'unset';
          this.firstScroll = false;
        } else if (!this.appService.mobile) {
          document.getElementById('main-header').style.display = 'none';
        }
        document.getElementById('sub-header').classList.remove('sticky');
        if (this.mobile) {
          if (
            this.url.indexOf('login') > -1 ||
            this.url.indexOf('register') > -1 ||
            this.url.indexOf('profile') > -1 ||
            this.url == '/about'
          ) {
            document.getElementById('header-offset').style.height = '80px';
          } else {
            document.getElementById('header-offset').style.height = '140px';
          }
        } else {
          document.getElementById('header-offset').style.height = '80px';
        }
      }
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.mobile = document.documentElement.clientWidth <= 768 ? true : false;
    this.mobileMenu =
      document.documentElement.clientWidth <= 991 ? true : false;
    if (document.getElementById('header-offset')) {
      if (this.mobileMenu) {
        // alert()
        if (
          this.url.indexOf('login') > -1 ||
          this.url.indexOf('register') > -1 ||
          this.url.indexOf('profile') > -1 ||
          this.url == '/about'
        ) {
          document.getElementById('header-offset').style.height = '80px';
        } else {
          document.getElementById('header-offset').style.height = '140px';
        }
      } else {
        document.getElementById('header-offset').style.height = '80px';
      }
    }
  }
}
