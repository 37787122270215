import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import Swiper, { SwiperOptions } from 'swiper/bundle';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as algoliasearch from 'algoliasearch';
import { AuthService } from 'src/app/services/auth.service';
import { ScriptsService } from 'src/app/services/scripts.service';

const searchClient = algoliasearch(
  '8MA5GJR7QU',
  'ebbb5ecbfeaa76c44aa219b62a61a267'
);
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit {
  @Input() sliderData: any[];
  @Input() sliderId:string;
  @Input() titleAlign: string = "left";
  @Input() currentEventSwiperParam: SwiperOptions;
  @Input() sliderTitle:string;
  @Input() spaceBetween:number;
  @Input() collection:string;
  @Input() slidesPerView:any = 'auto';
  @Input() boxShadow:boolean = true;
  @Input() subtitle:string = null;
  @Input() buttonLabel:string = null;
  @Input() buttonLink:string = null;
  @Input() buttonClass:string;
  @Input() itemButtonLabel:string;
  @Input() link:string;
  @Input() searchArray:any;
  @Input() emptyState:any
  @Input() hasSearch:boolean;
  @Input() searchContainerWidth:string='';
  currentEventSwiper: Swiper;
  randomNumber: number = Math.random();
  localAppService: AppService;
  itemId: string = Math.random().toString().split('.')[1];
  config = {
    searchClient
  };
  constructor(private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private script: ScriptsService,
    private router: Router) { 
  }
  algoliaSearchParam:any = {}

  ngOnInit(): void {
   
    // console.log(this.searchArray, this.sliderId)
    console.log(this.sliderData)
    this.localAppService = this.appService;
    if(this.appService.mobile ){
      this.currentEventSwiperParam.loop = false;
    }
    if(this.hasSearch){
      this.config['indexName'] = this.sliderId.split('-')[0]+'s';
    }
  }

  swiperNext(){
    this.currentEventSwiper.slideNext();
  }

  swiperPrev(){
    this.currentEventSwiper.slidePrev()
  }
  navigateTo(link=null, itemId=null){
    // console.log(itemId)
    if(link && (link.indexOf('http') > -1 || link.indexOf('instagram') > -1)){
      if (link.indexOf('instagram') > -1){
        window.open('https://' +link, '_blank');
      }else{
        window.open(link, '_blank');
      }
        // console.log(link)
       
    }else if (link && itemId){
      // console.log(link, itemId)
      this.router.navigate([link + '/' + itemId]);
    }else {
      this.router.navigate([link])
    }
    // console.log(window.location.pathname)
    // this.router.navigate([link])
  }


  ngAfterViewInit(){
    console.log(window.location.pathname.indexOf('profile') == -1)
    let collection = this.sliderId.indexOf('community') > -1 ? 'communities' : this.sliderId.split('-')[0] + 's'
    // if((this.sliderId == 'community-slider' || this.sliderId == 'campaign-slider' || this.sliderId == 'fund-slider') && (window.location.pathname.indexOf('profile') == -1)){
    //   if(this.sliderId == 'fund-slider'){
    //     // this.sliderData = [];
    //     this.authService.db.collection('featuredFunds').get().then(
    //       (docList)=>{
    //         this.sliderData = []
    //         docList.forEach(
    //           (doc)=>{
    //             let data = doc.data();
    //             data.itemId = doc.id;
    //             this.sliderData.push(data);
    //           }
    //         )
    //       }
    //     ).finally(
    //       ()=>{
    //         this.sliderData.reverse()
    //       }
    //     )
    //   }else{
    //     // this.sliderData = [];
    //   this.authService.db.collection(collection).limit(6).get().then(
    //     (docList)=>{
    //       this.sliderData = []
    //       docList.forEach(
    //         (doc)=>{
    //           let data = doc.data();
    //           data.itemId = doc.id;
    //           this.sliderData.push(data);
    //         }
    //       )
    //     }
    //   )
    //   }
      
    //   console.log(this.sliderData, this.sliderId)
    // }else 
    if(this.sliderId == 'instagram-slider'){
      let curator = {src: "https://cdn.curator.io/published/8cdc237e-58bd-4bd5-af8f-f58331f25c45.js", id: "curator"} ;
      this.script = new ScriptsService;
      this.script.load(curator)
    }
    // else{
    //   // alert()
    //   this.sliderData = this.sliderData.reverse();
    //   console.log(this.sliderData, this.sliderId)
    // }
    setTimeout(
      ()=>{
        if(this.sliderId != 'instagram-slider'){
          this.currentEventSwiperParam.spaceBetween = 20;
          if((document.getElementsByClassName(this.sliderId)).length > 1){
            this.currentEventSwiper =  new Swiper((<HTMLDivElement>(document.getElementsByClassName(this.sliderId)[1])), this.currentEventSwiperParam)
          }else{
            this.currentEventSwiper =  new Swiper((<HTMLDivElement>(document.getElementsByClassName(this.sliderId)[0])), this.currentEventSwiperParam)
          }
        }
        // console.log(this.sliderClass+'-----'+ this.sliderId)
       
      },500
    )
  }

  swiperAutoplay(){
    if(this.currentEventSwiper){
      this.currentEventSwiper.autoplay.start()
    }
  }


}
