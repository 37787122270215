import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectRefinementList } from 'instantsearch.js/es/connectors';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-custom-refine',
  templateUrl: './custom-refine.component.html',
  styleUrls: ['./custom-refine.component.less']
})
export class CustomRefineComponent extends BaseWidget implements OnInit {
  @Input() filter : any;
  @Input() type: string;
  @Input() quickFilterAttribute: any;
  state: {
    items: { label: string; value: string}[];
    createURL: () => string;
    refine: (value: string) => void;
    canRefine: boolean;
    isShowingMore: boolean;
    toggleShowMore: () => void;
    canToggleShowMore: boolean;
  }
  localAppService: AppService;
  quickFilterValue: string[] = [];
  
  constructor(
    private appService: AppService,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) { 
    super('CustomRefineComponent');
  }

  ngOnInit(): void {
    this.localAppService = this.appService;
    console.log(this.quickFilterAttribute)
    this.appService.clearFilter.subscribe(
      ()=>{
        this.quickFilterValue = []
      }
    )
    this.createWidget(connectRefinementList, {
      attribute: this.type == 'quickFilter' ? this.quickFilterAttribute : this.filter.attribute,
      transformItems: (transformItem) =>{
        console.log(this.type)
        if(this.type == 'quickFilter'){
          transformItem.forEach(element => {
            console.log(this.filter.filter(elt=> elt.value.toLowerCase() == element.value.toLowerCase()))
            let label = this.filter.filter(elt=> elt.value.toLowerCase() == element.value.toLowerCase())
            if(label.length > 0){
              element.label = this.filter.filter(elt=> elt.value.toLowerCase() == element.value.toLowerCase())[0].label

            }
          });
        }
         if (this.type == 'date') {
          //  alert()
           transformItem.map((element) => {
             element.label = new Date(element.label).toDateString()
             console.log(element)
         })
        }
        return transformItem
      }
    });
    super.ngOnInit();
  }

  refine(event) {
    // console.log(event)
    if(this.type == 'quickFilter'){
      if(this.quickFilterValue.indexOf(event) > -1){
        this.quickFilterValue.splice(this.quickFilterValue.indexOf(event) , 1);
      }else{
        this.quickFilterValue.push(event);
      }
    }
    this.state.refine(event)
  }
  

}


