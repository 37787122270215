import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
declare let FB
@Injectable({
  providedIn: 'root',
})
export class AppService {
  showHeaderOffset: boolean;
  whiteHeader: boolean;
  openPositionSection: boolean = false;
  ngoListingId:string
  country: any;
  countries: any[] = [];
  editorContent:string;
  greenHeader: boolean;
  showForm: boolean;
  targetScroll: string;
  loadMoreListing: boolean = false;
  formId: string;
  clearFilter = new EventEmitter<void>();
  openListingFilter = new EventEmitter<void>();
  loadMoreInit = new EventEmitter<void>();
  userAgent = window.navigator.userAgent;
  storageUrl: string =
    'https://storage.googleapis.com/livelove-dev.appspot.com/';
  is11orLower =
    this.userAgent.indexOf('Trident/') > 0 ||
    this.userAgent.indexOf('MSIE ') > 0;
  mobile: boolean = document.documentElement.clientWidth > 768 ? false : true;
  constructor(private router: Router, private authService: AuthService) {}

  stringIncludes(str: string, keyword: string) {
    if (!str || keyword == null || keyword == undefined) return false;
    if (this.is11orLower) {
      return str.indexOf(keyword) > -1;
    } else {
      return str.includes(keyword);
    }
  }

  shareWithFacebook() {
    FB.ui(
      {
        method: 'share',
        href: 'https://developers.facebook.com/docs/',
      },
      function (response) {}
    );
  }

  openForm(formId = null, shouldLogin = true) {
    console.log(shouldLogin, 'should loginnnnn');
    if (this.authService.loggedIn || !shouldLogin) {
      if (formId) {
        this.formId = formId;
      }
      this.showForm = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.router.navigate(['/login']);
    }
  }
}


