import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandFormat'
})
export class ThousandFormatPipe implements PipeTransform {

  transform(num):String{
    num = +num;
    //return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000)) + 'k' : Math.sign(num)*Math.abs(num) + ''
    if(num > 999 && num < 1000000){
      return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    }else if(num <= 999){
        return num; // if value < 1000, nothing to do
    }
    // var parts = x.toString().split(".");
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return parts.join(".");      
  }
}
