<div class="listing-banner-container default-padding">
  <div class="centered-container">
    <div
      class="banner-container"
      *ngIf="type == 'fund'"
      [ngStyle]="{ padding: '35px 0' }"
    >
      <span class="createdAt">Created on: {{ createdAt | date }}</span>
      <span class="title">{{ data?.title }}</span>
      <span class="subtitle" *ngIf="data?.subtitle">{{ data?.subtitle }}</span>
      <span class="description">{{ data?.description }}</span>
    </div>
    <div
      class="banner-container"
      *ngIf="type == 'training'"
      [ngStyle]="{ padding: '35px 0' }"
    >
      <span class="title">Hello {{ localAuthService?.user?.firstName }},</span>
      <span class="training">{{ title }}</span>
    </div>
    <div
      class="banner-container"
      *ngIf="type == 'campaign'"
      [ngStyle]="{ padding: '35px 0' }"
    >
      <div class="flex-container space-between">
        <div class="section-left">
          <span class="title">{{ data?.title }}</span>
          <div class="flex-container space-between">
            <!-- <span class="profile-image"></span> -->
            <div class="profile-info">
              <span class="championed-by"
                >Championed by: {{ data?.firstName }}</span
              >
              <span class="message">{{ data?.message }}</span>
            </div>
          </div>
        </div>
        <div class="section-right" *ngIf="!localAppService?.mobile">
          <div class="friends-donated">
            <span class="donated">{{ data?.donors }}</span>
            <span class="total">/{{ data?.goal }}</span>
            friends
          </div>
          <span class="friends-donated">of {{ data?.firstName }} donated</span>
          <!-- <span class="days-left">{{data?.daysLeft}} days left</span> -->
          <span class="text">Help her reach her goal</span>
          <button type="button" class="liveLoveBtn" (click)="donateNow()">
            Donate now
          </button>
        </div>
      </div>
    </div>
    <div
      class="banner-container"
      *ngIf="type == 'event'"
      [ngStyle]="{ padding: '35px 0' }"
    >
      <div class="flex-container space-between">
        <div class="section-left">
          <span class="createdAt">{{ createdAt }}</span>
          <span class="title">{{ data?.title }}</span>
          <div class="flex-container space-between">
            <span class="profile-image"></span>
            <div class="profile-info">
              <span
                class="message"
                [ngStyle]="{
                  'text-transform': 'capitalize',
                  'line-height': '18px'
                }"
                >Organized by:</span
              >
              <span
                class="championed-by"
                [ngStyle]="{
                  'text-transform': 'capitalize',
                  'line-height': '18px'
                }"
                >{{ data?.organizedBy }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="section-right"
          *ngIf="!localAppService?.mobile && !data?.completed"
        >
          <button
            type="button"
            class="liveLoveBtn"
            (click)="joinEvent()"
            [disabled]="
              localAuthService?.user &&
              localAuthService?.user.joinedEvent &&
              localAuthService?.user?.joinedEvent.length > 0 &&
              localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
            "
          >
            <span
              class="check-icon"
              *ngIf="
                localAuthService?.user &&
                localAuthService?.user.joinedEvent &&
                localAuthService?.user?.joinedEvent.length > 0 &&
                localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
              "
            >
              <img
                src="https://storage.googleapis.com/livelove-dev.appspot.com/check-icon.png"
                alt=""
              />
            </span>
            {{
              localAuthService?.user &&
              localAuthService?.user.joinedEvent &&
              localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
                ? "going"
                : "Join Event"
            }}
          </button>
          <span
            class="cancel-join"
            *ngIf="
              localAuthService?.user &&
              localAuthService?.user.joinedEvent &&
              localAuthService?.user?.joinedEvent.length > 0 &&
              localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
            "
            >Changed your mind?
            <a class="link" (click)="cancelEvent()">Cancel registration </a>
          </span>
          <button
            type="button"
            class="liveLoveBtn green-button"
            *ngIf="data?.fundId"
            (click)="donateNow()"
          >
            Donate now
          </button>
        </div>
        <div
          class="section-right"
          *ngIf="!localAppService?.mobile && data?.completed"
        >
          <button type="button" class="transparent-btn">
            <span class="check-icon">
              <img
                src="https://storage.googleapis.com/livelove-dev.appspot.com/check-green.png"
                alt=""
              />
            </span>
            completed
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="image default-padding" *ngIf="(localAppService?.mobile && data?.image || localAppService?.mobile && data?.video) && !data?.quill && content.length > 0 && content[0].type != 'image' && content[0].type != 'video' ">
    <img [src]="data?.image ? (data?.image.indexOf('http') > -1 ? data?.image : localAppService?.storageUrl + data?.image) : localAppService?.storageUrl + data?.video">
</div> -->
<div
  class="section-right"
  *ngIf="localAppService?.mobile && type == 'campaign'"
>
  <div class="friends-donated">
    <span class="donated">{{ data?.donatedFriends }}</span>
    <span class="total">/{{ data?.goal }}</span>
    friends
  </div>
  <span class="friends-donated">of {{ data?.firstName }} donated</span>
  <!-- <span class="days-left">{{data?.daysLeft}} days left</span> -->
  <span class="text">Help her reach her goal</span>
  <button type="button" class="liveLoveBtn" (click)="donateNow()">
    Donate now
  </button>
</div>

<div class="section-right" *ngIf="localAppService?.mobile && type == 'event'">
  <button type="button" class="liveLoveBtn" (click)="joinEvent()">
    {{
      localAuthService?.user &&
      localAuthService?.user.joinedEvent &&
      localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
        ? "going"
        : "Join Event"
    }}
  </button>
  <span
    class="cancel-join"
    *ngIf="
      localAuthService?.user &&
      localAuthService?.user.joinedEvent &&
      localAuthService?.user?.joinedEvent.indexOf(data?.id) > -1
    "
    >Changed your mind?<a class="link" (click)="cancelEvent()">
      Cancel registration
    </a>
  </span>
  <button type="button" class="liveLoveBtn green-button" (click)="donateNow()">
    Donate now
  </button>
</div>
