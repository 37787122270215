<mat-form-field class="custom-refine" *ngIf="type=='select' || type == 'date'">
    <mat-label>{{filter.selectValue}}</mat-label>
    <mat-select >
        <mat-option *ngFor="let item of state.items" [selected]="item.value" [value]="item.value" (click)="refine(item.value)">
            {{item.label}}
        </mat-option>
    </mat-select> 
</mat-form-field>

<div class="flex-container" [ngClass]="{'quick-wrapper': localAppService?.mobile}" *ngIf="type=='quickFilter'">
    <span class="quick-filter" 
    (click)="refine(elt.value)"
    [ngClass]="{selected: quickFilterValue.indexOf(elt.value) > -1}"
    [ngStyle]="{'color': 'black' , 'border': '1px solid #000000'}" 
    *ngFor="let elt of state.items">{{elt.label}}</span>
</div>
