import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { DataCallService } from '../services/data-call.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  footerMenu: any[]=[
    {"label": "home", "link": "/"},
    {"label": "store", "link": "https://livelovebracelet.com/collections/buy-to-donate"},
    {"label": "communities", "link": "/communities"},
    {"label": "Fundraising", "link": "/funds"},
    {"label": "recycle", "link": "https://liveloverecycle.com"},
    {"label":'Rebuild', "link":'https://livelove.org/rebuild_beirut'},
    {"label": "volunteer", "link": "http://livelovevolunteer.com"},
    {"label": "about", "link": "/about"},
  ];
  subscribeEmail:string;
  year:number = (new Date()).getFullYear();
  localAppService: AppService;
  subscribeForm:FormGroup
  footerContent: any;

  constructor(private appService: AppService, private dataCallService: DataCallService, private fb:FormBuilder, private authService: AuthService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.subscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
    this.localAppService = this.appService;
    this.authService.db
     .collection('static-content')
     .doc(this.appService.country.value)
     .collection('static-content')
     .doc('footer')
     .get()
     .then((doc) => {
       if (doc.exists) {
         this.footerContent = doc.data().data;
         console.log(this.footerContent);
       }
    });
  }

  scrollTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  subscribe(value){
    console.log(this.subscribeForm)
    if(this.subscribeForm.valid){
      this.dataCallService.subscribeToMailchimp(value.email).subscribe();
      this.snackBar.open('Subscribed!', '×', {
        panelClass: 'success',
        verticalPosition: 'top',
        duration: 6000,
      });  
    }else{
      this.snackBar.open("Please enter your email", '×', { panelClass: 'error', verticalPosition: 'top', duration: 6000 });       

    }
  }


}
