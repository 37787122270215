import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-title-with-seperator',
  templateUrl: './big-title-with-seperator.component.html',
  styleUrls: ['./big-title-with-seperator.component.less']
})
export class BigTitleWithSeperatorComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
