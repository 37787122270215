<div mat-dialog-content [ngClass]="{'fund-success': id == 'fund-success'}">
    <div class="community-team flex-container space-between" *ngIf="id == 'community-team-details'">
        <div class="profile">
            <img src="">
        </div>
        <div class="content">
            <span class="title">{{content.name}}</span>
            <span class="description">{{content.desc}}</span>
        </div>
    </div>
    <div class="success-popup flex-container space-between" *ngIf="id == 'fund-success'">
        <div class="content">
            <span class="description">{{content}}</span>
        </div>
    </div>
</div>