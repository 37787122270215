<div class="header-container default-padding" id="main-header" [ngClass]="{'white-header' : localAppService.whiteHeader && !localAppService?.greenHeader, 'green-header': localAppService?.greenHeader, 'box-shadow': url.indexOf('login') > -1 || url.indexOf('register') > -1 || url.indexOf('profile') > -1}" (window:resize)="onResize()" (window:scroll)="onScroll()">
    <div class="centered-container header-content">
        <span class="header-logo-container" routerLink="/">
            <img class="header-logo" src="https://storage.googleapis.com/livelove-dev.appspot.com/liveloveLogo.png">
        </span>
        <div class="flex-container">
            <div class="country-page-dropdown">
                <span [ngStyle]="{'position': 'absolute'}">{{localAppService.country.value}}</span>
                <mat-select [(value)]="localAppService.country.value" (selectionChange)="switchCountry($event)" disableOptionCentering panelClass="panelClass">
                    <mat-option *ngFor="let elt of localAppService?.countries" [value]="elt.value">{{elt.label}}</mat-option>
                </mat-select>
            </div>
            <mat-toolbar color="primary" *ngIf="mobileMenu" [ngStyle]="{'width': 'fit-content'}">
                <a class="header-menu-item profile-label" routerLink="/profile" ></a>
               <div [ngStyle]="{'margin-left':'10px'}">
                   <button mat-icon-button (click)="onToggleSidenav()">
                       <mat-icon>menu</mat-icon>
                   </button>
               </div>
           </mat-toolbar>
        </div>
      
       
        <div class="header-menu flex-container" *ngIf="!mobileMenu">
            <ng-container *ngFor="let elt of headerMenu">
                <div class="profile-dropdown" [ngClass]="{'selected': fullUrl.indexOf('profile') > -1}" *ngIf="localAuthService?.loggedIn && elt.link == '/login'">
                    <span class="profile-label" [ngClass]="{'selected': fullUrl.indexOf('profile') > -1}" >{{localAuthService?.user?.firstName}}</span>
                    <mat-select (selectionChange)="navigateProfile($event)" disableOptionCentering panelClass="panelClass">
                        <mat-option *ngFor="let elt of profileDropdown" [value]="elt">{{elt.label}}</mat-option>
                    </mat-select>
                </div>
                <div class="dropdown"
                    *ngIf="elt.isDropdown" [ngClass]="{'selected': fullUrl.indexOf(elt.link) > -1}" >
                    <span class="profile-label" [ngClass]="{'selected': fullUrl.indexOf(elt.link) > -1}" >{{elt.label}}</span>
                    <mat-select (selectionChange)="navigateTo($event)" disableOptionCentering panelClass="panelClass">
                        <mat-option *ngFor="let sub of elt.subLink" [value]="sub">{{sub.label}}</mat-option>
                    </mat-select>
                </div>
                <a class="header-menu-item seperate-line profile-label" *ngIf="elt.link == '/login' && !localAuthService?.loggedIn && !elt.isDropdown" routerLinkActive="selectedLogin"  [routerLinkActiveOptions]="{exact:true}" [routerLink]="elt.link">{{elt.label}}</a>
                <a class="header-menu-item" *ngIf="elt.link != '/login' && elt.link.indexOf('http') == -1 && !elt.isDropdown" [ngClass]="{'selected': fullUrl.indexOf(elt.link) > -1 && elt.link != '/', 'highlight': elt.link == '/funds'}"  [routerLinkActiveOptions]="{exact:true}" [routerLink]="elt.link" (click)="getSubCategories(elt.subCategory)" >{{elt.label}}</a>
                <a class="header-menu-item" *ngIf="elt.link != '/login' && elt.link.indexOf('http') > -1 && !elt.isDropdown" [href]="elt.link" target="_blank" (click)="getSubCategories(elt.subCategory)" >{{elt.label}}</a>
            </ng-container>
        </div>
    </div>
</div>
<div class="sub-header-container default-padding" id="sub-header" [ngClass]="{'green-header': localAppService?.greenHeader}" *ngIf="(subCategories && subCategories.length > 0 && localAppService.whiteHeader && url != '/') || (subCategories && subCategories.length > 0 && localAppService.greenHeader && url != '/') ">
    <div class="centered-container header-content">
        <span class="header-logo-container">
            <img class="header-logo" [src]="url.indexOf('funds') > -1 ? 'https://storage.googleapis.com/livelove-dev.appspot.com/live-love-fund-logo.png' : url.indexOf('volunteer') > -1 ? 'https://storage.googleapis.com/livelove-dev.appspot.com/livelove-volunteer-logo.png' : 'https://storage.googleapis.com/livelove-dev.appspot.com/liveloveLogo.png'">
        </span>
      
        <div class="country-page-dropdown">
            <span [ngStyle]="{'position': 'absolute'}">{{localAppService.country.value}}</span>
            <mat-select [(value)]="localAppService.country.value" (selectionChange)="switchCountry($event)"
                disableOptionCentering panelClass="panelClass">
                <mat-option *ngFor="let elt of localAppService?.countries" [value]="elt.value">{{elt.label}}</mat-option>
            </mat-select>
        </div>
        <div class="header-menu flex-container">
            <ng-container *ngFor="let elt of subCategories">
                <a class="header-menu-item" *ngIf="elt.link.indexOf('http') == -1" routerLinkActive="selected" [routerLinkActiveOptions]="{exact:true}" [ngClass]="{'seperate-line': elt.link == '/login', 'profile-label': elt.link == '/login'}" [routerLink]="elt.link" (click)="getSubCategories(elt.subCategory)" >{{elt.label}}</a>
                <a class="header-menu-item" *ngIf="elt.link != '/login' && elt.link.indexOf('http') > -1" [href]="elt.link" target="_blank" (click)="getSubCategories(elt.subCategory)" >{{elt.label}}</a>
            </ng-container>
        </div>
    </div>
</div>

<div class="header-offset" id="header-offset" *ngIf="showHeaderOffset || localAppService.whiteHeader || localAppService?.greenHeader"></div>
