
<ais-instantsearch [config]="config" >
    <ais-configure
    [searchParameters]="algoliaSearchParam"
    ></ais-configure>
    <ais-search-box [ngStyle]="{'display':'none'}"></ais-search-box>
    <div class="listing-community-page" *ngIf="!localAppService?.showForm">   
        <div class="listing-banner-container default-padding">
            <div class="centered-container">
                <div class="banner-container">
                    <span class="page-quick-text" *ngIf="quickText">{{quickText}}</span>
                    <span class="title">{{title}}</span>
                    <div class="quick-filter-container" *ngIf="quickFilters && !localAppService?.mobile">
                        <span class="label">Quick Filters</span>
                        <app-custom-refine [type]="'quickFilter'" [quickFilterAttribute]="quickFilterAttribute" [filter]="quickFilters"></app-custom-refine>
                        <!-- <div class="flex-container">
                            <span class="quick-filter" 
                            (click)="quickFilterClick(elt.value)"
                            [ngClass]="{'green': elt.background == 'green', blue: elt.background == 'blue', selected: elt.value == quickFilterValue}"
                            [ngStyle]="{'color': elt.background == 'transparent' ? 'black' : 'white', 'border': elt.background == 'transparent' ? '1px solid #000000' : 'inherit'}" 
                            *ngFor="let elt of quickFilters">{{elt.label}}</span>
                        </div> -->
                    </div>
                    <div class="flex-container search-container listing" [ngStyle]="{'justify-content': searchArray.length == 3 ? 'space-between' : 'flex-start'}" >
                        <ng-container class="search-mobile" *ngIf="localAppService?.mobile">
                            <div class="input-search search-input-type">
                                <ais-search-box class="input" [autofocus]="true" [searchAsYouType]="true" autocomplete="off" 
                                 placeholder="Search by name…."></ais-search-box>
                            </div>
                            <div class="input-search filter-btn">
                                <span class="filter-icon">
                                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/filter-icon.png" alt="filter-icon">
                                </span>
                                <button type="button" class="solid-btn"  (click)="openlistingFilter()" >filter</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!localAppService?.mobile">
                            <div class="input-search" *ngFor="let elt of searchArray ; let i = index" [ngClass]="{'search-input-type': elt?.type == 'search'}" [ngStyle]="{'width': elt.width}">
                                <ais-search-box class="input" *ngIf="elt?.type == 'search'" [autofocus]="true" [searchAsYouType]="true" autocomplete="off" 
                                 [placeholder]="elt.placeholder"></ais-search-box>
                                <app-custom-refine *ngIf="elt?.type == 'select' || elt?.type == 'date'" [type]="elt?.type" [filter]="elt"></app-custom-refine>
                            </div>
                        </ng-container>
                    </div>
                    <div class="filter-mobile-container" *ngIf="openFilter">
                        <span class="close-icon" (click)="closeListingFilter()"></span>
                        <span class="title">Filter events</span>
                        <div class="quick-filter-container" *ngIf="quickFilters && quickFilters.length > 0">
                            <span class="label">Quick Filters</span>
                            <app-custom-refine [type]="'quickFilter'" [quickFilterAttribute]="quickFilterAttribute" [filter]="quickFilters"></app-custom-refine>
                            <!-- <div class="flex-container quick-wrapper">
                                <span class="quick-filter" 
                                (click)="quickFilterClick()"
                                [ngClass]="{'green': elt.background == 'green', blue: elt.background == 'blue'}"
                                [ngStyle]="{'color': elt.background == 'transparent' ? 'black' : 'white', 'border': elt.background == 'transparent' ? '1px solid #000000' : 'inherit'}" 
                                *ngFor="let elt of quickFilters">{{elt.label}}</span>
                            </div> -->
                        </div>
                        <div class="input-search" *ngFor="let elt of searchArray ; let i = index" [ngClass]="{'search-input-type': elt?.type == 'search'}" [ngStyle]="{'width': elt.width}">
                            <ais-search-box class="input" *ngIf="elt?.type == 'search'" [autofocus]="true" [searchAsYouType]="true" autocomplete="off" 
                             [placeholder]="elt.placeholder"></ais-search-box>
                            <app-custom-refine [type]="'select'" *ngIf="elt?.type == 'select' || elt?.type == 'date'" [filter]="elt"></app-custom-refine>
                        </div>
                        <app-custom-clear></app-custom-clear>
                        <button type="button" class="liveLoveBtn">Apply Filter</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="community-container default-padding">
            <div class="centered-container">
             
                <ais-infinite-hits [transformItems]="productRenderer" [ngStyle]="{'width':'100%'}" >
                    <ng-template let-hits="hits" let-refineNext="showMore" let-isLastPage="isLastPage" >
                        <div *ngFor="let hit of hits" style="display: none;"></div>
                        <app-empty-state [text]="emptyState.text" [link]="emptyState.link" *ngIf="hits && hits.length == 0"></app-empty-state>
                        <div class="flex-container active-tags-container" *ngIf="!localAppService?.mobile && hits && hits.length > 0">
                            <span class="active-tag" *ngIf="activeFilters.length > 0">Active tags</span>
                            <ais-current-refinements [transformItems]="transformItems" [excludedAttributes]="['category', 'cause.category']"></ais-current-refinements>
                            <app-custom-clear ></app-custom-clear>
        
                        </div>
                        <div class="flex-container products-container">
                            <div class="item" [ngStyle]="{'margin-right': i%4 == 0 && i%4 != 0 ? '0px' : '20px'}" [ngClass]="{'gig-item': id == 'gig-slider'}" *ngFor="let elt of hits; let i = index">
                                <app-listing-item [data]="elt" 
                                    [id]="id"   
                                    (openNGOListing)="filterByNGO($event)"
                                    [sliderClass]="sliderClass"
                                ></app-listing-item>
                            </div>
                        </div>
                        <div class="load-more-container" *ngIf="!isLastPage && hits && hits.length > 0">
                            <button class="load-more-btn" (click)="refineNext($event); loadMore()"
                            >Load more {{loadMoreLabel}}</button>
                            <ul class="navigation flex-container" *ngIf="isLoadMore">
                                <ng-container *ngFor="let elt of navigation; let i = index">
                                    <li class="navigation-dot" id="dot-{{i}}"></li>
                                </ng-container>
                            </ul>
                        </div> 
                    </ng-template>
                </ais-infinite-hits>
                <!-- <div class="load-more-container">
                    <button class="load-more-btn" (click)="loadMore()"
                    [disabled]="state.isLastPage" [ngClass]="{'disabled': state.isLastPage}" >Load more {{loadMoreLabel}}</button>
                    <ul class="navigation flex-container" *ngIf="localAppService?.loadMoreListing">
                        <ng-container *ngFor="let elt of navigation; let i = index">
                            <li class="navigation-dot" id="dot-{{i}}" [ngClass]="{'disabled': state.isLastPage}"></li>
                        </ng-container>
                    </ul>
                </div> -->
                <!-- <ais-hits [transformItems]="productRenderer" [ngStyle]="{'width':'100%'}">
                    <ng-template let-hits="hits">
                        <div *ngFor="let hit of hits" style="display: none;"></div>
                        <div class="flex-container space-between">
                            <div class="item" [ngClass]="{'gig-item': id == 'gig-slider'}" *ngFor="let elt of data">
                                <app-listing-item [data]="elt" 
                                    [id]="id"   
                                    [link]="url"
                                    [buttonClass]="elt?.completed ? 'blue-button' : 'liveLoveBtn'"
                                ></app-listing-item>
                            </div>
                        </div>
                    </ng-template>
                </ais-hits> -->
                <!-- <app-custom-load *ngIf="data && data.length > 0" [loadMoreLabel]="loadMoreLabel"></app-custom-load> -->
            </div>
        </div>
    </div>
</ais-instantsearch>
