import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.less']
})
export class PoliciesComponent implements OnInit {
  topics:any[]=[];
  @Input() data:any;
  @Input() isFaq:boolean;
  faqData: any;
  constructor() { }

  ngOnInit(): void {
    this.faqData = this.data.answers[0];
    this.topics = this.data.topics;
  }

  getData(topic){
    this.faqData = this.data.answers.filter(elt=> elt.question == topic)[0]
    console.log(this.faqData)
  }

}
