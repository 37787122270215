import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireUploadTask } from '@angular/fire/storage/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  uploadCount: number;
  uploadLimit: number;
  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  error: boolean = false;
  percentage: Observable<number>[] = [];
  currentUpload : boolean = false;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  imageNames: string[] = [];
  imageUrls: Observable<string>[] = [];

  reporter: Subject<any> = new Subject<any>();
  private subs : Subscription[] = [];
  currentUploadTag : string;

  constructor(private storage: AngularFireStorage, private snackBar: MatSnackBar) { }
  

  resetUploaderData() {
    this.imageUrls = [];
    this.imageNames =[];
    this.uploadCount = 0;
    this.currentUploadTag = undefined;
  }
  uploadSequentially(event: FileList, tag?:string) {
    this.resetUploaderData()
    this.currentUploadTag = tag;
    this.uploadLimit = event.length;
    this.uploadCount = 0;
    this.startUpload(event)
    this.uploadCount++;
    
  }
  startUpload(uploadFiles : FileList) {
    // The File object
    const file = uploadFiles[this.uploadCount];
    // Client-side validation 
    console.log(file.type.indexOf('pdf'))
    if (file.type.split('/')[0] !== 'image' && file.type.indexOf('pdf') == -1) { 
      this.snackBar.open('unsupported file type :(', '×', { panelClass: 'error', verticalPosition: 'top', duration: 6000 }); 

      console.error('unsupported file type :( ')
      return;
    }
    this.currentUpload = true;
    const fileName = new Date().getTime()+'_'+file.name;
    // The storage path
    const path = this.currentUploadTag ? `${this.currentUploadTag}/${fileName}` : `${fileName}`; 
    const fileRef = this.storage.ref(path);
    // Totally optional metadata
    const customMetadata = { app: 'My AngularFire-powered PWA!' };
  
    // The main task
    this.task = this.storage.upload(path, file, { customMetadata })
  
  
  
    // Progress monitoring
    this.subs.push(this.task.percentageChanges().subscribe(
      (data)=>{
        if(data) {
          this.reporter.next(data);
        }
      }
    ))
    this.percentage.push(this.task.percentageChanges());

    this.snapshot   = this.task.snapshotChanges();
  
    // The file's download URL
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe((data)=>{this.imageNames.push(data)})
        this.imageUrls.push(this.downloadURL);
        if(this.uploadCount < this.uploadLimit) { 
          this.startUpload(uploadFiles);
          this.uploadCount++;
        } else {
          this.currentUpload = false;
          this.reporter.next({urls: this.imageUrls, names: this.imageNames, count: this.uploadCount, tag: this.currentUploadTag})
        }
      })
   ).subscribe()
  }
  unsubscribeAll() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
