<div class="banner-container" [ngClass]="{'black-shadow': blackShadow, 'big-banner': bigBanner}" [ngStyle]="{'height': height, 'background': background, 'background-image': 'url('+ (image ? (image.indexOf('http') > -1 ? image : localAppService?.storageUrl + image) : '' ) +')', 'padding': boxPadding, 'align-items': alignItems, 'justify-content': justifyContent }">
    <span class="title" *ngIf="title && linkInTitle && linkInTitle.label" [ngStyle]="{'margin':titleMargin, 'max-width': titleWidth, 'text-align': titleAlign, 'font-size': titleFont, 'color':color}">{{title}}
        <a class="link-title" (click)="navigateTo(linkInTitle.link)">{{linkInTitle.label}}</a>
    </span>

    <span class="title noWidow" *ngIf="title && (!linkInTitle || !linkInTitle.label)" [ngStyle]="{'margin':titleMargin, 'max-width': titleWidth, 'text-align': titleAlign, 'font-size': titleFont, 'color':color, 'text-transform': url.indexOf('profile') > -1 ? 'capitalize' : 'unset'}">{{title}}
    </span>
    
    <span class="text noWidows" *ngIf="text" [ngStyle]="{'margin':textMargin, 'font-weight':textWeight,'color':color, 'max-width': textWidth, 'font-size': textFont, 'text-align': titleAlign}">{{text}}</span>
    <span class="separator" *ngIf="showSeparator"></span>
    <div class="button-container flex-container" [ngStyle]="{'justify-content': buttons.length > 1 ? 'space-between' : 'center'}" *ngIf="buttons && buttons.length > 0">
        <button type="button" (click)="emitFunction(elt.link, elt.target, elt.id)"  class="{{elt.buttonClass}}"  *ngFor="let elt of buttons">
            {{elt.label}}
        </button>
    </div>
    <div class="centered-container  video-container" *ngIf="hasVideo">
        <div class="flex-container space-between" >
            <div class="sticker" *ngIf="sticker && !localAppService?.mobile">
                <img [src]="sticker" alt="sticker">
            </div>
            <div class="youtube-video">
                <div class="play-btn">
                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/play-btn.png" alt="play-btn">
                </div>
            </div>
            
        </div>
    </div>
</div>
