import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.less']
})
export class ProgressSpinnerComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  setProgress(percent) {
   
  }

}
