import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment.prod';
import Swiper, { SwiperOptions } from 'swiper/bundle';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, AfterViewInit {  

  homeSlider:boolean = true;
  color: string = 'white';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  mobile:boolean = document.documentElement.clientWidth > 768 ? false : true;
  db = firebase.default.firestore();
  constructor() { }

  ngOnInit(): void {
    let widow = document.getElementsByClassName('title')
        for(let i=0; i < widow.length; i++){
          widow[i].innerHTML.replace(/\s([^\s<]+)\s*$/,'\u00A0$1')
          console.log(widow[i].innerHTML.replace(/\s([^\s<]+)\s*$/,'\u00A0$1'))
        }     
    // $('.title').each(function(i,d){
    //   console.log($(d).html( $(d).text()))
    //   $(d).html( $(d).text().replace(/\s([^\s<]+)\s*$/,'\u00A0$1'))
    // });
    // $('.subtitle').each(function(i,d){
    //   console.log($(d).html( $(d).text()))
    //   $(d).html( $(d).text().replace(/\s([^\s<]+)\s*$/,'\u00A0$1'))
    // });
  }

  

  ngAfterViewInit(){
    
  }

 

  @HostListener("window:resize", [])
    onResize(){
      this.mobile = document.documentElement.clientWidth > 768 ? false : true;
    }
    
}
