import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.less']
})
export class EmptyStateComponent implements OnInit {
  @Input() text:any[]=[];
  @Input() link:string;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateTo(link){
    this.router.navigate([link])
  }

}
