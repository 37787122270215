<app-banner *ngIf="showBanner" [background]="bannerContent.background" 
    [blackShadow]="false"
    [title]="bannerContent.title" 
    [text]="bannerContent.text" [textFont]="bannerContent.textFont" [titleFont]="bannerContent.titleFont" [textWeight]="bannerContent.textWeight" [showSeparator]="bannerContent.showSeparator" >
</app-banner>
<div class="centered-container"  [ngStyle]="{'margin': margin}">
    <div [ngStyle]="{'position': 'relative'}" [ngClass]="{'flex-container': align == 'left'}">
        <span [ngClass]="{'sticker-top': align == 'center', 'sticker': align == 'left'}" *ngIf="stickerLeft">
            <img [src]="stickerLeft" alt="form-top">
        </span>
        <div class="google-form-container default-padding" [ngStyle]="{'margin': bigContainer}" [ngClass]="{'default-padding': localAppService?.showForm}">
            <span class="title" *ngIf="formData.title">{{formData.title}}</span>
            <span class="subtitle" *ngIf="formData.subtitle && formData.subtitle.length > 1"><span class="green">{{formData.subtitle[0]}}</span> {{formData.subtitle[1]}}</span>
            <span class="subtitle" *ngIf="formData.subtitle && formData.subtitle.length == 1">{{formData.subtitle[0]}}</span>

            <form (ngSubmit)="sendGoogleForn()">
                <div class="form-container" [ngStyle]="{'margin': formMargin}">
                    <div class="input-wrapper" [ngClass]="{'radio': elt.type == 'radio'}" *ngFor="let elt of formData.formElement; let i = index">
                        <span class="label" *ngIf="elt.label && elt.type != 'small-title' && elt.type != 'header'">{{elt.label}}</span>
                        <!-- <span class="sublabel" *ngIf="elt.sublabel">{{elt.sublabel}}</span> -->
                        <span class="header" *ngIf="elt.type == 'header'">{{elt.label}}</span>
                        <div class="social-input" [ngClass]="{'has-icon': elt.social != ''}">
                            <mat-form-field *ngIf="elt?.type == 'text' && ( elt?.label.toLowerCase() != 'email' || (elt?.label.toLowerCase() == 'email' && !localAuthService?.loggedIn)) ">
                                <input matInput type="text" [(ngModel)]="elt.value" [placeholder]="elt.placeholder" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                            <mat-form-field *ngIf="elt?.type == 'text' && elt?.label.toLowerCase() == 'email' && localAuthService?.loggedIn">
                                <input matInput type="text" [(ngModel)]="localAuthService.user.email" [placeholder]="elt.placeholder"
                                    [ngModelOptions]="{standalone: true}" readonly>
                            </mat-form-field>
                            <mat-form-field *ngIf="elt?.type == 'number'" >
                                <input matInput type="number" [(ngModel)]="elt.value" [placeholder]="elt.placeholder" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                            <mat-form-field *ngIf="elt.type == 'email'" >
                                <input matInput type="email" [(ngModel)]="elt.value" email [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                            <span class="social-icon {{elt.social}}"  *ngIf="elt.social != ''">
                                <img [src]="'https://storage.googleapis.com/livelove-dev.appspot.com/' + elt.social" alt="elt.social">
                            </span>
                        </div>
                        <div [ngClass]="{'radio-block': elt.display == 'block'}" *ngIf="elt.type == 'radio'">
                            <mat-radio-group 
                                [(ngModel)]="elt.value" [ngModelOptions]="{standalone: true}">
                                <mat-radio-button class="radio-button" *ngFor="let opt of elt.options" [value]="opt">
                                    {{opt}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div [ngClass]="{'radio-block': elt.display == 'block'}" *ngIf="elt.type == 'checkbox'">
                            <mat-checkbox [(ngModel)]="elt.value[j]" [ngModelOptions]="{standalone: true}" *ngFor="let opt of elt.options; let j = index">
                                {{opt}}
                            </mat-checkbox>
                        </div>
                        <div class="flex-container date-container"  *ngIf="elt?.type == 'date'">
                            <mat-form-field [ngStyle]="{'width': '100%'}">
                                <input [(ngModel)]="elt.value" [ngModelOptions]="{standalone: true}" matInput [matDatepicker]="eventDate" placeholder="" readonly>
                                <mat-datepicker-toggle matSuffix [for]="eventDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #eventDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="fill" *ngIf="elt?.type == 'select'">
                            <mat-select [(ngModel)]="elt.value" disableOptionCentering panelClass="panelClass" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let opt of elt.options" [value]="opt">
                                {{opt.title ? opt.title : opt}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="i == 3 && id == 'fund-form' && (elt.value != 'monthly' && elt.value)  " [ngStyle]="{'margin-top':'20px'}" >
                            <input matInput type="number" placeholder="max of 60 days, but we recommend 40." max="60" [(ngModel)]="elt.value" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field  *ngIf="elt?.type == 'textarea'" [ngStyle]="{'height':'120px'}">
                            <textarea [(ngModel)]="elt.value" [ngModelOptions]="{standalone: true}" rows="6" matInput ></textarea>
                        </mat-form-field>
                        <div class="fuzone" *ngIf="elt.type == 'uploader'">
                            <div class="fu-text" *ngIf="images && images[i]">
                                <!-- <img [src]="images[i]" style="max-width:200px" alt=""> -->
                                <iframe [src]="images[i]" height="200" width="200"></iframe>
                            </div>
                            <label for="files{{i}}" class="uploader-btn">Browse images</label>
                            <input type="file" id="files{{i}}" style="visibility:hidden;" (change)="uploadSequentially($event.target.files, i)"  accept=".pdf,.doc, .jpg, .png" class="upload">
                            <mat-progress-bar *ngIf="uploading" [value]="currentPercentage"></mat-progress-bar>
                        </div>
                        <span class="title" *ngIf="elt.type == 'small-title'">{{elt.label}}</span>
                    </div>
                    <div class="input-wrapper" *ngIf="id == 'fund-form'" >
                        <quill-editor (onEditorChanged)="changeEditor($event)" (onEditorCreated)="editorCreated($event)" [style]="{'height':'320px'}" [modules]="editorModules"  placeholder="Customize your fund content here! You can embed video links, uplod images and add some content."></quill-editor>
                        <!-- <div [froalaEditor]="options" (beforeUpload)="beforeUpload($event)" [(froalaModel)]="editorContent"></div> -->
                        <!-- <quill-view-html [content]="editorContent" theme="snow"></quill-view-html> -->
                        <input id="imageInput" hidden class="d-none" type="file" accept="image/*" (change)="doImageUpload($event.target.files)">

                    </div>
                    <!-- <div [froalaEditor] [(froalaModel)]="editorContent" >Hello, Froala!</div> -->
                    <button type="submit" class="liveLoveBtn">{{formData.formSubmitBtn}}</button>
                </div>
            </form>
        </div>
        <span [ngClass]="{'sticker-bottom': align == 'center', 'sticker': align == 'left'}" *ngIf="stickerRight">
            <img [src]="stickerRight" alt="form-bottom">
        </span>
    </div>
    
</div>
