<div class="default-padding">
    <div class="centered-container">
        <div class="flex-container space-between policy-container" [ngClass]="{'is-faq': isFaq}">
            <div class="sections-container" *ngIf="isFaq">
                <span class="topic" [ngClass]="{'selected': elt == faqData.question}" (click)="getData(elt)" *ngFor="let elt of topics">{{elt}}</span>
            </div>
            <div class="answers-container">
                <span class="title">{{faqData.title}}</span>
                <div class="content" *ngFor="let elt of faqData.data">
                    <span class="subtitle">{{faqData.subtitle}}</span>
                    <span class="text" *ngFor="let text of elt.text">{{text}}</span>
                </div>
                
            </div>
        </div>
    </div>
</div>