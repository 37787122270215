import { Component, Inject, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectClearRefinements } from 'instantsearch.js/es/connectors';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-custom-clear',
  templateUrl: './custom-clear.component.html',
  styleUrls: ['./custom-clear.component.less']
})
export class CustomClearComponent  extends BaseWidget implements OnInit {
  state: {
    hasRefinements: boolean;
    refine: Function;
    createURL: Function;
    widgetParams: object;
  }
  constructor(
    private appService: AppService,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) { 
    super('CustomClearComponent');
  }

  ngOnInit(): void {
    this.createWidget(connectClearRefinements, {
      // instance options
    });
    super.ngOnInit();
  }

  refine(){
    this.appService.clearFilter.emit()
    this.state.refine()
  }

}
