 <div class="image-container {{position}}" [id]="url + '-' + randomNumber" [ngClass]="{'have-icon': hasIcon, 'is-logo': isLogoImage}" [ngStyle]="{'width': width}" (window:resize)="onResize()">
    <img [src]="url.indexOf('http') > -1 ? url : localAppService?.storageUrl + url" [alt]="url">
    <div class="content" *ngIf="hasIcon">
        <span class="icon" *ngIf="icon">
            
            <img [src]="icon" [alt]="icon">
        </span>
        <span class="text" *ngIf="text">{{text}}</span>
    </div>
</div>
