<div class="landing-sections-container" *ngIf="loaded">
    <section class="home-page-caroussel default-padding" *ngIf="data?.introMedia" [ngClass]="{'gallery-mode': data?.introMedia?.length > 1}">
        <div class="centered-container" [ngStyle]="{'width': '100%', 'height': '100%', 'position': 'relative'}"> 
            <ul class="gallery-navigation  flex-container " *ngIf="data?.introMedia?.length > 1">
                <ng-container *ngFor="let bannerItem of data?.introMedia; let i = index">
                    <li class="gallery-navigation-dot" id="dot-{{i}}" (click)="galleryNavigate(i, false)" [ngClass]="{'active': i == galleryOptions.index}">0{{i + 1}}</li>
                </ng-container>
            </ul>
            <div class="scroll-down-button" *ngIf="!mobile">
                <div class="wrap">
                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/arrow-logo.png" id="arrow" class="animated bounce">
                </div>
                {{data?.scrollDownLabel}}
            </div>
        </div>
        <div class="carousel-slider" *ngFor="let elt of data?.introMedia; let i = index" 
            [ngClass]="{'active': i == galleryOptions.index}"
            [ngStyle]="{'background-image': 'url(' + elt.image + ')' }">
            <div class="slider-text centered-container default-padding">
                <span class="title noWidow" id="widoww" *ngIf="elt.title">{{elt.title}}</span>
                <span class="subtitle" *ngIf="elt.subtitle && !mobile" >{{elt.subtitle}}</span>
            </div>
        </div>
    </section>

   
    <div *ngFor="let elt of data?.sections; let i = index" [ngStyle]="{'margin-bottom': i == data?.sections.length - 1 && data.sections[i].type != 'icons-banner' ? '70px': 'unset', 'margin-top': data?.sections[i].type == 'icons-banner' || data?.sections[i].type.indexOf('slider') > -1 || (!data?.introMedia && i == 0) ? '0' : ( localAppService?.mobile ? '50px' : '100px')}">
        <div class="green-banner" *ngIf="elt?.type == 'green-banner'">
            <div class="centered-container">
                <div class="flex-container green-content space-between" [ngStyle]="{'justify-content': elt?.text ? 'space-between' : 'center'}">
                    <span class="text-bold" [ngStyle]="{'max-width': '490px', 'text-align': elt?.text ? 'left' : 'center' }">{{elt?.title}}</span>
                    <span class="text" *ngIf="elt?.text">{{elt?.text}} </span>
                </div>
            </div>
        </div>
        <div class="fund-grid default-padding" *ngIf="elt?.type == 'fund-grid'">
            <div class="centered-container">
                <div class="title-container flex-container space-between">
                    <span class="title">{{elt?.title}}</span>
                    <button type="button" (click)="navigateTo(elt?.buttonLink, null, elt?.id)" class="liveLoveBtn">{{elt?.buttonLabel}}</button>
                </div>
                <div class="grid-container1 flex-container space-between">
                    <div class="section-left" >
                        <div class="image" [ngStyle]="{'margin-bottom': '5px'}">
                            <div [id]="elt?.gridData[0].image">
                                <img [src]="elt?.gridData[0].image">
                            </div>
                            <div class="content">
                                <span class="title">{{elt?.gridData[0].title}}</span>
                                <span class="text">{{elt?.gridData[0].subtitle}}</span>
                            </div>
                        </div>
                      
                    </div>
                    <div class="section-right">
                        <div class="image" [ngStyle]="{'margin-bottom': '5px'}" >
                            <div [id]="elt?.gridData[1].image">
                                <img [src]="elt?.gridData[1].image">
                            </div>
                            <div class="content">
                                <span class="title">{{elt?.gridData[1].title}}</span>
                                <span class="text">{{elt?.gridData[1].subtitle}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="image-text-flex default-padding" *ngIf="elt.type == 'image-text-flex'">
            <div class="centered-container">
                <div class="flex-container space-between wrapper">
                    <div class="content">
                        <span class="label-top">{{elt.labelTop}}</span>
                        <span class="title noWidow" >{{elt.title}}</span>
                        <span class="description">{{elt.desc}}
                        </span>
                        <div class="flex-container space-between buttons-container">
                            <button type="button" class="liveLoveBtn" (click)="navigateTo(elt.actionBtn.link, elt.actionBtn.target, elt.data.itemId)" >{{elt.actionBtn.label}}</button>
                            <button type="button" class="solid-btn" (click)="navigateTo(elt.noActionBtn.link)">{{elt.noActionBtn.label}}</button>
                        </div>
                    </div>
                    <div class="image" (click)="navigateTo(elt.actionBtn.link, null, elt.data.itemId)">
                        <app-image [ratio]="2.01" [url]="elt.image.indexOf('http') > -1 ? elt?.image : localAppService?.storageUrl + elt.image" ></app-image>
                        <div class="text">
                            <span class="title noWidow">{{elt.data[0].title}}</span>
                            <div class="flex-container space-between" [ngStyle]="{'align-items':'center'}">
                                <div class="green-container">
                                    <span class="green-info">${{(+elt.data[0].amountDonated).toFixed(0) | thousandFormat}} of ${{(+elt.data[0].amount).toFixed(0) | thousandFormat}}  </span>
                                    <span class="info-unit">{{elt.data.duration == 'monthly' ? 'raised ' + elt.data[0].duration : elt.data[0].duration}}</span>
                                </div>
                                <span class="black-info">From: {{elt.data[0].donors}} Donors</span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div *ngIf="elt.type == 'steps-section'">
            <section class="middle-banner default-padding" *ngFor="let item of elt?.data; let i = index" [ngStyle]="{'background': item.background}">
                <div class="centered-container">
                    <div class="content flex-container space-between">
                        <div class="banner-image" *ngIf="i == 1 && !localAppService?.mobile" >
                            <img [src]="item.image">
                        </div>
                        <div class="text-container">
                            <div [ngStyle]="{'width': 'fit-content', 'margin': 'auto'}">
                                <span class="step-number" [ngStyle]="{'background': item.circleColor}">Step {{i+1}}</span>
                                <span class="title noWidows" *ngIf="item.title">{{item.title}}</span>
                                <span class="text" *ngIf="item.text">{{item.text}}</span>
                                <button type="button" (click)="navigateTo(item.link, null, item.id)" *ngIf="item.buttonLabel" class="liveLoveBtn">{{item.buttonLabel}}</button>
                            </div>
                        </div>
                        <div class="banner-image" *ngIf="i == 0 || i == 2 || localAppService?.mobile">
                            <img [src]="item.image">
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="ambassador-sections default-padding" *ngIf="elt.type == 'left-right'">
            <div class="centered-container">
                <div class="flex-container space-between ambassador-step" *ngFor="let step of elt.data; let i = index">
                    <div class="image" *ngIf="i % 2 != 0 || localAppService?.mobile">
                        <img [src]="step.image.indexOf('http') > -1 ? step.image : localAppService?.storageUrl + step.image.split('.')[0] + '_650x560.png'" [alt]="step.image">
                    </div>
                    <div class="content">
                        <div class="wrapper">
                            <span class="title noWidow">{{step.title}}</span>
                            <span class="text">{{step.text}}</span>
                        </div>
                    </div>
                    <div class="image" *ngIf="!localAppService?.mobile && (i % 2 == 0 || i == 0)">
                        <img [src]="step.image.indexOf('http') > -1 ? step.image : localAppService?.storageUrl + step.image.split('.')[0] + '_650x560.png' " [alt]="step.image">
                    </div>
                </div>
            </div>
        </div>

        <div class="ambassador-sections default-padding" *ngIf="elt.type == 'text-right'">
            <div class="centered-container">
                <div class="flex-container space-between ambassador-step" *ngFor="let step of elt.data; let i = index">
                    <div class="image">
                        <img [src]="localAppService?.storageUrl + step.image.split('.')[0] + '_650x560.png'" [alt]="step.image">
                    </div>
                    <div class="content">
                        <div class="wrapper">
                            <span class="title noWidow">{{step.title}}</span>
                            <span class="text" *ngFor="let opt of step.text"  placeholder="text">{{opt}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ambassador-sections default-padding" *ngIf="elt.type == 'text-left'">
            <div class="centered-container">
                <div class="flex-container space-between ambassador-step" *ngFor="let step of elt.data; let i = index">
                    <div class="content">
                        <div class="wrapper">
                            <span class="title noWidow">{{step.title}}</span>
                            <span class="text" *ngFor="let opt of step.text" placeholder="text">{{opt}}</span>
                        </div>
                    </div>
                    <div class="image">
                        <img [src]="localAppService?.storageUrl + step.image.split('.')[0] + '_650x560.png'" [alt]="step.image">
                    </div>
                </div>
            </div>
        </div>

        <div class="impact-container default-padding" *ngIf="elt?.type == 'impact-banner'">
            <div class="centered-container">
                <div class="flex-container space-between">
                    <div class="item" *ngFor="let impact of elt?.data">
                        <span class="title">{{impact.title}}</span>
                        <span class="subtitle">{{impact.text}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="event-type-container p-l-20" *ngIf="elt?.type == 'event-logo-grid'">
            <div class="centered-container">
                <span class="title">{{elt?.title}}</span>
    
                <div class="flex-container event-container">
                    <div class="event" *ngFor="let item of elt?.data" [ngStyle]="{'background': item.background}">
                        <span class="logo">
                            <img src="https://storage.googleapis.com/livelove-dev.appspot.com/liveloveLogo.png" alt="livelove">
                        </span>
                        <span class="community-name">{{item.community}}</span>
                        <span class="icon">
                            <img [src]="item.image" [alt]="item.image">
                        </span>
                        <span class="event-name">{{item.event}}</span>
                    </div>
                </div>
            </div>
        </div>

        <section class="logos-container default-padding" [ngStyle]="{'background-image':'url(' + elt?.backgroundImage + ')'}" *ngIf="elt.type == 'icons-banner'" [id]="elt.type">
            <span class="title noWidow" *ngIf="elt?.title">{{elt?.title}}</span>
            <div class="centered-container">
                <div class="flex-container space-between logos">
                    <div class="container" *ngFor="let item of elt?.data">
                        <span class="logo" *ngIf="item.image">
                            <img [src]="item?.image" [alt]="item.image">
                        </span>
                        <span class="impact" *ngIf="item?.title">{{item?.title}}</span>
                        <span class="text" *ngIf="item?.text">{{item?.text}}</span>
                    </div>
                </div>
            </div>
        </section>    

        <section class="start-community-banner default-padding" *ngIf="elt.type == 'flex-icons'">
            <span class="title noWidow">{{elt.title}}</span>
            <div class="centered-container">
                <div class="flex-container community-points space-between">
                    <div class="point" *ngFor="let item of elt.data">
                        <span class="image">
                            <img [src]="item.image" alt="point1">
                        </span>
                        <span class="title noWidow">{{item.title}}</span>
                        <span class="text">{{item.text}}</span>
                    </div>
                </div>
            </div>
            <button class="solid-btn" type="button" *ngIf="elt.button" (click)="emitFunction(elt.button.function, elt.button.link, elt.button.target, elt.button.formId)" >{{elt.button.label}}</button>
        </section>
        <div class="separation" *ngIf="elt.type == 'big-title'">
            <app-big-title-with-seperator [title]="elt.title"></app-big-title-with-seperator>
        </div>
        <section class="volunteer-events-banner" *ngIf="elt.type == 'volunteer-event-banner'">
            <div class="main-banner default-padding" [ngStyle]="{'background-image': 'url('+ elt.mainBanner +')'}">
                <div class="centered-container main-banner-container" *ngIf="elt.title || elt.subtitle">
                    <div class="info-box">
                        <span class="title">{{elt.title}}</span>
                        <span class="event-date">{{elt.subtitle}}</span>
                        <span class="description">{{elt.description}}</span>
                        <span class="organizer">{{elt.organizer}}</span>
                        <button type="button" (click)="navigateTo(elt.buttonLink)" class="liveLoveBtn">{{elt.buttonLabel}}</button>
                    </div>
                </div>
            </div>
            <div class="flex-container space-between secondary-banner">
                <div class="image">
                    <img [src]="elt.image1" alt="image2">
                </div>
                <div class="image">
                    <img [src]="elt.image2" alt="image3">
                </div>
                <div class="image">
                    <img [src]="elt.image3" alt="image4">
                </div>
            </div>
        </section>
        <div class="two-listings-grid default-padding" *ngIf="elt.type == 'two-listing-grid'">
            <div class="centered-container">
                <div class="flex-container space-between wrapper">
                    <div class="event-container">
                        <span class="title">{{elt.sectionLeft.title}}</span>
                        <span class="description">{{elt.sectionLeft.description}}</span>
                        <app-empty-state [text]="elt?.sectionLeft.emptyState.text" [link]="elt?.sectionLeft.emptyState.link" *ngIf="elt.sectionLeft.data.length == 0"></app-empty-state>
                        <div class="listing-events flex-container space-between" *ngIf="elt.sectionLeft.data.length > 0">
                            <div class="item" *ngFor="let item of elt.sectionLeft.data">
                                <app-listing-item 
                                    [data]="item"
                                    [type]="item.type"
                                    [id]="elt.sectionLeft.collection"
                                ></app-listing-item>
                            </div>
                        </div>
                    </div>
                    <div class="gigs-container">
                        <span class="title">{{elt.sectionRight.title}}</span>
                        <span class="description">{{elt.sectionRight.description}}</span>
                        <app-empty-state [text]="elt?.sectionRight.emptyState.text" [link]="elt?.sectionRight.emptyState.link" *ngIf="elt.sectionRight.data.length == 0"></app-empty-state>
                        <!-- <app-search [inPage]="'slider'" *ngIf="searchArray && searchArray.length > 0" [justifyContent]="'flex-start'" [containerWidth]="'100%'" [searchArray]="searchArray"></app-search> -->
                        <div class="listing-gigs" *ngIf="elt.sectionRight.data.length > 0">
                            <div class="item" *ngFor="let item of elt.sectionRight.data; let i = index">
                                <app-listing-item [data]="item"
                                [type]="item.type"
                                [id]="elt.sectionRight.collection"></app-listing-item>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <section class="moto-banner default-padding" *ngIf="elt.type == 'moto-banner'">
            <div class="centered-container flex-container space-between">
                <div class="profile">
                    <img [src]="elt.image" alt="profile">
                </div>
                <div class="moto-container">
                    <span class="moto">{{elt.text}}</span>
                    <span class="author">{{elt.author}}</span>
                </div>
            </div>
        </section>
        <section class="default-banner" *ngIf="elt?.type == 'banner' || elt?.type == 'banner-big'">
            <app-big-title-with-seperator *ngIf="elt.hasBigTitle" [title]="elt.bigTitle"></app-big-title-with-seperator>
            <app-banner 
                [image]="elt.background ? null : elt.image"
                [text]="elt.text"
                [titleFont]="elt.titleFont"
                [color]="elt.color"
                [showSeparator]="elt?.showSeparator"
                [background]="elt.background ? elt.background : null"
                [blackShadow]="elt.blackShadow" 
                [bigBanner]="elt.type == 'banner-big' ? true : false" 
                [buttons]="elt.buttons" 
                [linkInTitle]="elt.linkInTitle"
                [justifyContent]="elt.justifyContent"
                [title]="elt.title">
            </app-banner>
            <div class="sticker" *ngIf="elt.stickImageToBanner">
                <img [src]="elt?.stickyImage" alt="sticker">
            </div>
        </section>

        <section class="three-image-box default-padding {{elt.type}}" *ngIf="elt?.type == 'three-images-section'">
            <div class="centered-container">
                <span class="title noWidow" *ngIf="elt.title">{{elt.title}}</span>
                <span class="subtitle" *ngIf="elt.subtitle">{{elt.subtitle}}<span class="subtitle-bold" *ngIf="elt.highlightedText"> {{elt.highlightedText}}</span> </span>
                <div class="flex-container space-between images-container">
                    <div class="image-text boxShadow" [ngClass]="{'boxShadow': item.hasShadow}" *ngFor="let item of elt.data">
                        <app-image 
                            [ratio]="item.ratio" 
                            [position]="item.position" 
                            [hasIcon]="item.hasIcon" 
                            [icon]="item.icon" [text]="item.text" 
                            [url]="item.image" >
                        </app-image>
                        <div class="text-box">
                            <span class="text">{{item.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>

        <section class="three-image-box default-padding" *ngIf="elt?.type == 'three-images-section-extra'">
            <div class="centered-container">
                <span class="title noWidow" *ngIf="elt.title">{{elt.title}}</span>
                <div class="subtitle-button flex-container space-between" *ngIf="elt.button.label">
                    <span class="subtitle">{{elt.subtitle}}</span>
                    <button type="button" class="liveLoveBtn" [routerLink]="elt.button.link" >{{elt.button.label}}</button>
                </div>
                <div class="flex-container space-between images-container">
                    <div class="image-text boxShadow" [ngClass]="{'boxShadow': item.hasShadow}" *ngFor="let item of elt.data">
                        <app-image 
                            [ratio]="item.ratio" 
                            [position]="item.position" 
                            [hasIcon]="item.hasIcon" 
                            [icon]="item.icon" [text]="item.text" 
                            [url]="item.image" >
                        </app-image>
                        <div class="text-box">
                            <span class="text">{{item.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>

        <section class="feedbacks-container default-padding" *ngIf="elt?.type == 'feedback-section'">
            <div class="centered-container" [ngStyle]="{'position': 'relative'}">
                <span class="arrow-right" *ngIf="!localAppService?.mobile" (click)="navigateRight(elt.data)">
                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/right-arrow.png" alt="arrow-right">
                </span>
                <div class="feedbacks-navigation">
                    <div class="feedback-container" *ngFor="let item of elt.data; let i = index" [ngClass]="{'active': i == galleryOptions.index}" >
                        <div class="content">
                            <div class="flex-container">
                                <span class="qotation" *ngIf="localAppService?.mobile">
                                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/quotation-image.png" alt="quotation">
                                </span>
                                <span class="feedback">{{item.feedback}}</span>
                                <span class="qotation" *ngIf="!localAppService?.mobile">
                                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/quotation-image.png" alt="quotation">
                                </span>
                            </div>
                            <span class="reviewer">{{item.reviewer}}</span>
                        </div>
                    </div>
                    <ul class="gallery-navigation  flex-container" *ngIf="elt.data.length > 1">
                        <ng-container *ngFor="let bannerItem of elt.data; let i = index">
                            <li class="gallery-navigation-dot" id="dot-{{i}}" (click)="galleryNavigate(i)" [ngClass]="{'active': i == galleryOptions.index}"></li>
                        </ng-container>
                    </ul>
                </div>
                <span class="arrow-left" *ngIf="!localAppService?.mobile" (click)="navigateLeft(elt.data)">
                    <img src="https://storage.googleapis.com/livelove-dev.appspot.com/left-arrow.png" alt="arrow-left">
                </span>
            </div>
        </section>

        <section class="fund-slider default-padding" *ngIf="elt?.type == 'title-4-items'">
            <div class="centered-container">
                <span class="title">{{elt.title}}</span>
                <app-empty-state [text]="elt?.emptyState.text" [link]="elt?.emptyState.link" *ngIf="elt.data.length == 0"></app-empty-state>
                <div class="fund-options flex-container space-between" *ngIf="elt?.data.length > 0">
                    <div class="item" *ngFor="let item of elt?.data" (click)="navigateTo(item.link ? item.link : null, itemId)">
                        <app-listing-item [data]="item" [sliderClass]="elt?.type" [id]="elt?.collection ? elt?.collection : elt?.type"></app-listing-item>
                    </div>
                </div>
                <button type="button" class="liveLoveBtn" *ngIf="elt?.buttonLabel" (click)="navigateTo(elt.buttonLink ? elt.buttonLink : null, elt?.id)" (click)="openForm()">{{elt?.buttonLabel}}</button>
                <button type="button" class="solid-btn" *ngIf="elt?.noActionBtnLabel" (click)="navigateTo(elt.noActionBtnLink ? elt.noActionBtnLink : null, elt?.id)">{{elt?.noActionBtnLabel}}</button>
            </div>
        </section>

        <section class="slider-container" *ngIf="elt?.type.indexOf('slider') > -1"> <!--[searchArray]="elt?.sliderClass == 'fund-slider' ? (localAppService?.mobile ? fundSearchMobile : fundSearchArray) : elt.sliderClass == 'champions-slider' ? (localAppService?.mobile ? championSearchMobile : championSearchArray) : null "-->
            <app-slider class="swiper-full" 
                [hasSearch]="elt.hasSearch"
                [emptyState]="elt.emptyState"
                [searchArray]="elt.searchArray"
                [buttonLabel]="localAppService?.mobile ? null : elt.buttonLabel" 
                [buttonLink]="localAppService?.mobile ? null : elt.buttonLink" 
                [sliderTitle]="elt.sliderTitle" 
                [subtitle]="elt.subtitle" 
                [sliderData]="elt.data" 
                [currentEventSwiperParam]="swiperParam"
                [collection]="elt?.collection ? elt?.collection : null"
                [sliderId]="elt.type">
            </app-slider>
            <button class="liveLoveBtn" [ngStyle]="{'margin-left': '20px'}" (click)="navigateTo(elt.buttonLink)" type="button" *ngIf="elt.buttonLabel && localAppService?.mobile">{{elt.buttonLabel}}</button>
        </section>

        <div class="fund-video default-padding" *ngIf="elt.type == 'video-banner'">
            <span class="title noWidow">{{elt.title}}</span>
            <div class="centered-container video-container">
                <iframe class="video" 
                    [src]="elt?.video">
                </iframe>
            </div>
        </div>

        <div class="default-padding details-container" *ngIf="elt.type == 'faq-style'">
            <div class="centered-container">
                <span class="title" *ngIf="elt?.title">{{elt?.title}}</span>
                <span class="seperator" *ngIf="elt?.title"></span>
                <div class="flex-container space-between question-container">
                    <div class="sections-container">
                        <div *ngFor="let item of elt?.data; let j = index">
                            <div class="topic-container">
                                <span class="topic" [ngClass]="{'selected': (selectedDetails && item.question == selectedDetails.question) || (!selectedDetails && j == 0)}" (click)="getData(i, j)" >{{item.question}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="answers-container" id="answers-container" *ngIf="selectedDetails; else noData">
                        <div class="content" *ngFor="let element of selectedDetails.text">
                            <span class="text">{{element}}</span>
                        </div>
                    </div>
                    <ng-template #noData>
                        <div class="answers-container" id="answers-container">
                            <div class="content" *ngFor="let element of elt?.data[0].text">
                                <span class="text">{{element}}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

        <section class="listing-section" *ngIf="elt.type == 'listing-section'">
            <div class="centered-container">
                <div class="section-title flex-container space-between">
                    <span class="title noWidow">{{elt.title}}</span>
                    <button class="liveLoveBtn" type="button" (click)="navigateTo(elt.buttonLink)" *ngIf="elt.buttonLabel">{{elt.buttonLabel}}</button>

                    <!-- <app-search [inPage]="'slider'" *ngIf="searchArray && searchArray.length > 0" [justifyContent]="'flex-end'" [searchArray]="localAppService?.mobile ? searchArrayMobile : searchArray"></app-search> -->
                </div>
                <app-empty-state [text]="elt?.emptyState.text" [link]="elt?.emptyState.link" *ngIf="elt.data.length == 0"></app-empty-state>
                <div class="flex-container communities space-between" *ngIf="elt.data.length > 0">
                    <div class="item" *ngFor="let item of elt.data">
                        <app-listing-item [data]="item" 
                            [sliderClass]="elt?.type"
                            [id]="elt.collection ? elt?.collection : elt?.type">
                        </app-listing-item>
                    </div>
                </div>
            </div>
        </section>

        <section class="middle-banner default-padding" *ngIf="elt?.type == 'banner-grid'">
            <div class="centered-container">
                <div class="content flex-container space-between">
                    <div class="banner-image" *ngIf="elt.direction == 'text-right'">
                        <img [src]="elt.image.indexOf('http') > -1 ? elt?.image : localAppService?.storageUrl + elt.image">
                    </div>
                    <div class="text-container">
                        <span class="title noWidow">{{elt.title}}</span>
                        <span class="text" *ngFor="let opt of elt.text; let i = index">{{elt.text.length > 1 ? (i + 1) + '.' : ''}} {{opt}}</span>
                        <button type="button" *ngIf="elt?.buttonLabel" (click)="navigateTo(elt.buttonLink)" class="solid-btn">{{elt.buttonLabel}}</button>
                    </div>
                    <div class="banner-image {{elt?.direction}}" *ngIf="elt.direction == 'text-left'">
                        <img [src]="elt.image.indexOf('http') > -1 ? elt?.image : localAppService?.storageUrl + elt.image">
                    </div>
                </div>
            </div>
        </section>

        <section class="banner-grid-fullScreen"  *ngIf="elt?.type == 'banner-grid-fullScreen'">
            <div class="flex-container">
                <div class="image">
                    <img [src]="localAppService?.storageUrl + elt.image.split('.')[0] + '_1280x825.png'" [alt]="elt.image">
                </div>
                <div class="content-container">
                    <div class="content">
                        <span class="title noWidow">{{elt.title}}</span>
                        <span class="text">{{elt.text}}</span>
                        <button type="button" (click)="navigateTo(elt.buttonLink)" class="solid-btn">{{elt.buttonLabel}}</button>
                    </div>
                </div>
            </div>
        </section>
      
       
    </div>
</div>

