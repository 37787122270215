import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { PreloadAllModules } from '@angular/router';
import { UserResolverService } from './services/user-resolver.service'
import { AboutComponent } from './about/about.component';
import { TestingComponent } from './testing/testing.component';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [UserResolverService],
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'testing',
        component: TestingComponent,
      },
      {
        path: 'volunteers',
        loadChildren: async () =>
          (await import('./volunteers/volunteers.module')).VolunteersModule,
      },
      {
        path: 'funds',
        loadChildren: async () =>
          (await import('./funds/funds.module')).FundsModule,
      },
      {
        path: 'communities',
        loadChildren: async () =>
          (await import('./communities/communities.module')).CommunitiesModule,
      },
      {
        path: 'login',
        loadChildren: async () =>
          (await import('./auth/auth.module')).AuthModule,
      },
      {
        path: 'register',
        loadChildren: async () =>
          (await import('./auth/auth.module')).AuthModule,
      },
      {
        path: 'profile',
        loadChildren: async () =>
          (await import('./profile/profile.module')).ProfileModule,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      { path: ':id', component: DynamicPageComponent },
      { path: ':category/:id', component: DynamicPageComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: "top",
    initialNavigation: 'enabled'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
