import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Sanitizer, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/services/app.service';
import { UploadService } from 'src/app/services/upload.service';
import { MapsAPILoader  } from '@agm/core';

import { DataPostService } from 'src/app/services/data-post.service';
import { AuthService} from '../../services/auth.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import {Quill} from 'quill';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-google-form',
  templateUrl: './google-form.component.html',
  styleUrls: ['./google-form.component.less'],
})
export class GoogleFormComponent implements OnInit, OnDestroy {
  @Input() buttonLabel: string;
  @Input() formId: string = null;
  @Input() align: string;
  @Input() stickerRight: string;
  @Input() stickerLeft: string;
  @Input() showBanner: boolean = false;
  @Input() bannerContent: any;
  @Input() margin: string;
  @Input() formMargin: string;
  @Input() bigContainer: string;
  @Input() id: string;
  @Input() number: number;
  @Input() formData: any;
  @Input() funds: any;
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  lat = 51.678418;
  lng = 7.809007;

  editorContent: string = '';
  quillEditor: Quill;
  marker: any = {
    lat: 51.673858,
    lng: 7.815982,
    label: 'A',
    draggable: true,
  };
  zoom: number = 8;
  localAppService: AppService;
  showForm: boolean = false;
  images: any[] = [];
  uploading: boolean;
  currentPercentage: any = {};
  editorModules = {
    toolbar: {
      container: [
        [{ header: 2 }],
        ['bold', 'video', 'image'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };
  indexImage: number = null;
  localAuthService: AuthService;

  constructor(
    private storage: AngularFireStorage,
    private dataPostService: DataPostService,
    private authService: AuthService,
    private upload: UploadService,
    private appService: AppService,
    public sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.localAuthService = this.authService;
    this.localAppService = this.appService;
    this.upload.reporter.subscribe(
      (data) => {
        console.log(data);
        let done = data.urls;
        if (!done) {
          this.uploading = true;
          this.currentPercentage = data;
        } else {
          this.uploading = false;
          setTimeout(
            ()=>{
              if (data.tag) {
                this.images[data.tag] = this.sanitizer.bypassSecurityTrustResourceUrl(data.names[0]) 
              }
            },1000
          )
        }
      },
      (e) => {
        console.log(e);
      }
    );
    if (this.appService.formId) {
      // alert()
      this.formId = this.appService.formId;
      console.log(this.formId, this.formData);
    }
    if (!this.formData) {
      // alert()
      this.formData = {
        title: '',
        subtitle: '',
        formElement: [],
        formSubmitBtn: '',
      };
      this.authService.db
        .collection('forms')
        .doc(this.formId)
        .get()
        .then((doc) => {
          // alert()
          if (doc.exists && doc.data()) {
            let form = JSON.parse(doc.data().items);
            console.log(form, doc.data());
            this.formData.title = doc.data().title;
            this.formData.formSubmitBtn = this.buttonLabel;
            console.log(doc.data());
            this.formData.subtitle = [doc.data().description];
            let i = 0;
            form.forEach((elt) => {
              let options = [];
              console.log(i);
              console.log(elt, elt.placeholder, 'eltttt');
              this.formData.formElement.push({
                label: elt.title,
                key: elt.description,
                placeholder: elt.placeholder ? elt.placeholder : '',
                display:
                  elt.type == 'MULTIPLE_CHOICE' ||
                  (elt.type == 'CHECKBOX' && elt.choices.length > 2)
                    ? 'block'
                    : null,
                social:
                  elt.title.toLowerCase().indexOf('facebook profile') > -1 ||
                  elt.title.toLowerCase().indexOf('facebook link') > -1
                    ? 'facebook-black.png'
                    : elt.title.toLowerCase().indexOf('instagram profile') >
                        -1 ||
                      elt.title.toLowerCase().indexOf('instagram link') > -1
                    ? 'instagram-black.png'
                    : elt.title.toLowerCase().indexOf('linkedin profile') >
                        -1 ||
                      elt.title.toLowerCase().indexOf('linkedin link') > -1
                    ? 'linkedin-black.png'
                    : '',
                value:
                  elt.type == 'DATE'
                    ? { month: '', day: '', year: '' }
                    : elt.type == 'CHECKBOX'
                    ? []
                    : null,
                type:
                  elt.type == 'LIST'
                    ? 'select'
                    : elt.type == 'PARAGRAPH_TEXT'
                    ? 'textarea'
                    : elt.type == 'FILE_UPLOAD'
                    ? 'uploader'
                    : elt.type == 'DATE'
                    ? 'date'
                    : elt.type == 'CHECKBOX'
                    ? 'checkbox'
                    : elt.type == 'MULTIPLE_CHOICE'
                    ? 'radio'
                    : elt.type == 'PAGE_BREAK'
                    ? 'small-title'
                    : elt.type == 'NUMBER'
                    ? 'number'
                    : elt.type == 'SECTION_HEADER'
                    ? 'header'
                    : 'text',
                required: elt.required,
                options: elt.choices,
              });
              i++;
            });
            console.log(this.formData);
          }
        });
    } else {
      console.log(this.formData);
    }
  }

  editorCreated(quill: any) {
    this.quillEditor = quill;
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
  }

  imageHandler() {
    document.getElementById('imageInput').click();
  }

  changeEditor(event: EditorChangeContent | EditorChangeSelection) {
    this.editorContent = event['editor']['root']['innerHTML'];
    this.appService.editorContent = event['editor']['root']['innerHTML'];
  }

  doImageUpload(event) {
    console.log(event[0].name);
    const filePath = '';
    const fileRef = this.storage.ref(event[0].name);
    const task: AngularFireUploadTask = fileRef.put(event[0]);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((downloadURL) => {
            console.log(downloadURL, 'downoad url');
            // event.target.value = ''
            // get current index of the cursor
            const currentIndex = this.quillEditor.getSelection().index;
            // insert uploaded image url to 'image' embed (quill does this for you)
            // the embed looks like this: <img src="{url}" />
            this.quillEditor.insertEmbed(currentIndex, 'image', downloadURL);
            this.quillEditor.insertText(currentIndex + 2, '');
            // set cursor position to after the image
            this.quillEditor.setSelection(currentIndex + 2, 0);
            // this.image.insert(downloadURL, null, null, this.image.get(), {link: downloadURL});
          });
        })
      )
      .subscribe();
  }

  sendGoogleForn() {
    console.log(this.id, 'idddddd');
    let formValid = true;

    for (let i = 0; i < this.formData.formElement.length; i++) {
      console.log(i, 'index');
      if (
        this.formData.formElement[i].type != 'small-title' &&
        this.formData.formElement[i].type != 'header'
      ) {
        if (this.formData.formElement[i].type == 'uploader' && this.images[i]) {
          // alert(this.images[i])
          this.formData.formElement[i].value = this.images[i].changingThisBreaksApplicationSecurity;
        }
        if (
         ( this.formData.formElement[i].key == 'teamImage' ||
          this.formData.formElement[i].key == 'partnersLogo' ||
          this.formData.formElement[i].key == 'image') && this.images[i]
        ) {
          let image = this.images[i].changingThisBreaksApplicationSecurity;
          console.log(image);
          this.formData.formElement[i].value = image;
        }
        if (
          this.formData.formElement[i].value == null &&
          this.formData.formElement[i].required
        ) {
          this.snackBar.open('Please add all required fields', '×', {
            panelClass: 'error',
            verticalPosition: 'top',
            duration: 10000,
          });
          formValid = false;
          break;
        } else {
          formValid = true;
        }
      }
    }

    if (formValid) {
      this.buttonLabel = JSON.parse(
        JSON.stringify(this.formData.formSubmitBtn)
      );
      this.formData.formSubmitBtn = 'Submitting ...';
      // this.formData.formSubmitBtn = 'Success'
      this.submitForm.emit(this.formData);
    }
  }

  uploadSequentially(files, i) {
    console.log(files, i, 'indexxxx');
    this.uploading = true;
    this.upload.uploadSequentially(files, i);
  }

  setIndex(i) {
    console.log(i, 'iiiiii');
  }
  ngOnDestroy() {
    this.appService.showForm = false;
  }
}
