<app-header (sidenavToggle)="openMobileMenu(); sidenav.toggle()" [greenHeader]="localAppService?.greenHeader" [whiteHeader]="localAppService?.whiteHeader"></app-header>
<div (window:resize)="onResize()">
    <mat-sidenav-container>
        <mat-sidenav #sidenav role="navigation">
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet *ngIf="closeMenu"></router-outlet>
            <div class="header-menu-mobile" *ngIf="!closeMenu">
                <div class="header-mobile-content">
                    <!-- <div class="country-page-dropdown" >
                        <div class="dropdown-container">
                            
                        </div>
                    </div> -->
                    
                    <div class="header-menu" >
                        <ng-container *ngFor="let elt of headerMenu">
                            <div class="dropdown" *ngIf="elt.isDropdown" [ngClass]="{'selected': fullUrl.indexOf(elt.link) > -1}">
                                <span class="profile-label" [ngClass]="{'selected': fullUrl.indexOf(elt.link) > -1}">{{elt.label}}</span>
                                <mat-select (selectionChange)="navigateTo($event)" disableOptionCentering panelClass="panelClass">
                                    <mat-option *ngFor="let sub of elt.subLink" [value]="sub">{{sub.label}}</mat-option>
                                </mat-select>
                            </div>
                            <a class="header-menu-item seperate-line profile-label"(click)="logout()" *ngIf="elt.link == '/login' && localAuthService?.loggedIn" >Sign out</a>
                            <a class="header-menu-item seperate-line profile-label"(click)="openMobileMenu(); sidenav.toggle()" *ngIf="elt.link == '/login' && !localAuthService?.loggedIn" routerLinkActive="selectedLogin"  [routerLinkActiveOptions]="{exact:true}" [routerLink]="elt.link">{{elt.label}}</a>
                            <a class="header-menu-item"(click)="openMobileMenu(); sidenav.toggle()" *ngIf="elt.link != '/login' && elt.link.indexOf('http') == -1 && !elt.isDropdown" [ngClass]="{'highlight': elt.link == '/funds'}"  [routerLinkActiveOptions]="{exact:true}" [routerLink]="elt.link">{{elt.label}}</a>
                            <a class="header-menu-item"(click)="openMobileMenu(); sidenav.toggle()" *ngIf="elt.link != '/login' && elt.link.indexOf('http') > -1 && !elt.isDropdown" [href]="elt.link" target="_blank">{{elt.label}}</a>
                        </ng-container>
                        <!-- <a class="header-menu-item" (click)="openMobileMenu(); sidenav.toggle()" [ngClass]="{'highlight': elt.link == '/funds'}" [routerLink]="elt.link" *ngFor="let elt of headerMenu">{{elt.label}}</a> -->
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<app-footer></app-footer>