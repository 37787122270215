import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {
  private scripts: any = {};

  constructor() { }

  load(script) {
    var promises: any[] = [];
    promises.push(this.loadScript(script));
    return Promise.all(promises);
  }
  loadScript(name: any) {
    console.log(name)
    return new Promise((resolve, reject) => {
            let script = (<HTMLScriptElement>document.createElement('script'));
            script.type = 'text/javascript';
            script.id = name.id;
            script.src = name.src;
            console.log(script)
            script.onload = () => {
                resolve({script: name.id, loaded: true, status: 'Loaded'});
            };
            script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
            document.getElementsByTagName('head')[0].appendChild(script);

    });
  }
}
