import { Injectable, Injector } from '@angular/core';
import { Router} from '@angular/router';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: firebase.default.firestore.DocumentData;
  loggedIn: boolean;
  db = firebase.default.firestore();
  apiUrl: string =
    'https://us-central1-livelove-dev.cloudfunctions.net/gateway/';
  private get appService() {
    return this._injector.get(AppService);
  }

  constructor(
    private snackBar: MatSnackBar,
    public router: Router,
    private _injector: Injector,
    public http: HttpClient
  ) {}

  register(values) {
    // alert('registerrrrrrr')
    // console.log('register', values)

    firebase.default
      .auth()
      .createUserWithEmailAndPassword(values['email'], values['password'])
      .then(
        (data) => {
          // console.log(data);
          data.user.updateProfile({ displayName: values['firstName'] });
          this.db
            .collection('customer')
            .doc(`${data.user.uid}`)
            .set(
              {
                email: values['email'],
                firstName: values['firstName'],
                lastName: values['lastName'],
              },
              { merge: true }
            )
            .then(() => {
              this.loggedIn = true;
              this.router.navigate(['/']);
            })
            .catch((error) => {
              // console.log(error)
              // let err = JSON.parse(error)
              this.snackBar.open(
                'Somthing went wrong! Please try again.',
                '×',
                { panelClass: 'error', verticalPosition: 'top', duration: 6000 }
              );
            });
        },
        (e) => {
          let message;
          // console.log('err',e);
          if (e['code'] && e['message']) {
            message = e['message'];
            status = 'error';
            this.snackBar.open(message, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 6000,
            });

            // console.log(message,'erro mesgggg')
          }
        }
      );
  }

  login(values) {
    // console.log(values, 'values on login')
    let message, status;
    firebase.default
      .auth()
      .signInWithEmailAndPassword(values['email'], values['password'])
      .then(
        (data) => {
          // console.log(data);
          this.loggedIn = true;
          this.router.navigate(['/']);
        },
        (e) => {
          // console.log('err',e);
          if (e['code'] && e['message']) {
            message = e['message'];
            status = 'error';
            this.snackBar.open(message, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 6000,
            });

            // console.log(message,'erro mesgggg')
          }
        }
      )
      .catch((e) => {
        this.snackBar.open('Somthing went wrong! Please try again.', '×', {
          panelClass: 'error',
          verticalPosition: 'top',
          duration: 6000,
        });

        // console.log(e)
      });
  }
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.default.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      firebase.default
        .auth()
        .signInWithPopup(provider)
        .then(
          (res) => {
            // console.log(res)
            if (res.additionalUserInfo.isNewUser) {
              this.db
                .collection('customer')
                .doc(`${res.user.uid}`)
                .set({
                  email: res.user.email,
                  firstName: res.user.displayName,
                })
                .then(() => {
                  this.loggedIn = true;
                  this.router.navigate(['/']);
                })
                .catch((error) => {
                  // console.log(error)
                  // let err = JSON.parse(error)
                });
            } else {
              // console.log('kjhgfghjh')
              this.router.navigate(['/']);
            }
            resolve(res);
          },
          (e) => {
            let message;
            // console.log('err',e);
            if (e['code'] && e['message']) {
              message = e['message'];
              status = 'error';
              this.snackBar.open(message, '×', {
                panelClass: 'error',
                verticalPosition: 'top',
                duration: 6000,
              });

              // console.log(message,'erro mesgggg')
            }
          }
        );
    });
  }
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.default.auth.FacebookAuthProvider();
      firebase.default
        .auth()
        .signInWithPopup(provider)
        .then(
          (res) => {
            console.log(res);
            if (res.additionalUserInfo.isNewUser) {
              this.db
                .collection('customer')
                .doc(`${res.user.uid}`)
                .set({
                  email: res.user.email,
                  firstName: res.user.displayName,
                })
                .then(() => {
                  this.loggedIn = true;
                  this.router.navigate(['/']);
                })
                .catch((error) => {
                  // console.log(error)
                  // let err = JSON.parse(error)
                });
            } else {
              // console.log('kjhgfghjh')
              this.router.navigate(['/']);
            }
            resolve(res);
          },
          (e) => {
            console.log(e);
            let message;
            // console.log('err',e);
            if (e['code'] && e['message']) {
              message = e['message'];
              status = 'error';
              this.snackBar.open(message, '×', {
                panelClass: 'error',
                verticalPosition: 'top',
                duration: 6000,
              });
              reject(e);
              // console.log(message,'erro mesgggg')
            }
          }
        );
    });
  }
  logout() {
    firebase.default
      .auth()
      .signOut()
      .then((data) => {
        // console.log(data);
        this.loggedIn = false;

        this.router.navigate(['/']);
      });
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.default.auth().onAuthStateChanged((user) => {
        if (user) {
          this.loggedIn = true;
          this.db
            .collection('customer')
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.user = doc.data();
                this.user['uid'] = user.uid;
                console.log(this.user, 'userrr');
                this.db
                  .collection('countries')
                  .get()
                  .then((docList) => {
                    let countries = [];
                    docList.forEach((doc) => {
                      countries.push(doc.data());
                    });
                    this.appService.countries = [
                      {
                        label: 'Beirut',
                        value: 'beirut',
                        audienceID: 'daa06694eb',
                      },
                    ]; //countries;
                    if (localStorage.getItem('country')) {
                      this.appService.country = JSON.parse(
                        localStorage.getItem('country')
                      );
                      console.log(this.appService.country.value);
                      resolve(user);
                    } else {
                      this.appService.country = {
                        label: 'Beirut',
                        value: 'beirut',
                        audienceID: 'daa06694eb',
                      };
                      localStorage.setItem(
                        'country',
                        JSON.stringify(this.appService.country)
                      );
                      console.log(this.appService.country.value);
                      resolve(user);
                    }
                  });
                // resolve(user);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          console.log(this.user);
          let uid = localStorage.getItem('uid')
            ? localStorage.getItem('uid')
            : uuidv4();
          if (!localStorage.getItem('uid')) {
            localStorage.setItem('uid', uid);
          }
          this.db
            .collection('countries')
            .get()
            .then((docList) => {
              let countries = [];
              docList.forEach((doc) => {
                countries.push(doc.data());
              });
              this.appService.countries = [
                { label: 'Beirut', value: 'beirut' },
              ]; //countries;
              if (localStorage.getItem('country')) {
                this.appService.country = JSON.parse(
                  localStorage.getItem('country')
                );
                console.log(this.appService.country.value);
                resolve(true);
              } else {
                this.appService.country = {
                  label: 'Beirut',
                  value: 'beirut',
                };
                localStorage.setItem(
                  'country',
                  JSON.stringify(this.appService.country)
                );
                console.log(this.appService.country.value);
                resolve(true);
              }
            });
          this.loggedIn = false;
          // resolve(true);
          // reject();
        }
      });
    });
  }

  updateProfileName(name) {
    // console.log(name)
    var user = firebase.default.auth().currentUser;
    user
      .updateProfile({ displayName: name })
      .then(
        () => {
          // console.log('UPDATED PROFILE NAME');
          firebase.default.auth().currentUser.reload();
          this.db
            .collection('customer')
            .doc('')
            .update({
              fullName: name,
            })
            .then(
              () => {},
              (e) => {}
            );
        },
        (e) => {}
      )
      .catch((e) => {
        // console.log(e);
      });
  }
  updateUserEmail(email) {
    var user = firebase.default.auth().currentUser;
    user
      .updateEmail(email)
      .then(
        () => {
          this.sendEmailVerify();
          this.db
            .collection('customer')
            .doc('')
            .update({
              email: email,
            })
            .then(
              () => {},
              (e) => {}
            );
        },
        (e) => {}
      )
      .catch((e) => {
        // console.log(e);
      });
  }

  resetUserPassword(email) {
    firebase.default
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        this.snackBar.open(
          'An email has been sent to you in order to reset your password',
          '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 6000 }
        );
      })
      .catch((error) => {
        this.snackBar.open('Something went wrong! Please try again.', '×', {
          panelClass: 'error',
          verticalPosition: 'top',
          duration: 6000,
        });
      });
  }

  sendEmailVerify() {
    var user = firebase.default.auth().currentUser;
    user
      .sendEmailVerification()
      .then(() => {})
      .catch((error) => {
        // console.log(error)
      });
  }

  changePassword(password) {
    let user = firebase.default.auth().currentUser;
    user
      .updatePassword(password)
      .then(() => {
        this.snackBar.open('Your password has been updated successfully', '×', {
          panelClass: 'success',
          verticalPosition: 'top',
          duration: 6000,
        });
      })
      .catch((error) => {
        this.snackBar.open('Something went wrong! Please try again.', '×', {
          panelClass: 'error',
          verticalPosition: 'top',
          duration: 6000,
        });
      });
  }
}
