<div class="footer">
    <div class="centered-container flex-container">
        <div class="footer-column social-media-links" >
            <span class="title">{{footerContent?.title}}</span>
            <span class="text">{{footerContent?.text}}</span>
            <div class="social-icons-container flex-container space-between">
                <a class="social-url" *ngIf="footerContent?.fbLink" [href]="footerContent?.fbLink" target="_blank">
                    <span class="facebook-icon">
                    </span>
                </a>
                <a class="social-url" *ngIf="footerContent?.twitterLink" [href]="footerContent?.twitterLink" target="_blank">
                    <span class="twitter-icon">
                        
                    </span>
                </a>
                <a class="social-url" *ngIf="footerContent?.instaLink"  [href]="footerContent?.instaLink" target="_blank">
                    <span class="instagram-icon"></span>
                </a>
            </div>
            <span class="text" [ngStyle]="{'margin-bottom':'5px'}">{{footerContent?.supportTitle}}</span>
            <a class="text" href="{{'mailto:'+ footerContent?.supportEmail}}">{{footerContent?.supportEmail}}</a>

            <span class="text">{{footerContent?.newsletterTitle}}</span>
            <form class="example-form" [formGroup]="subscribeForm" (ngSubmit)="subscribe(subscribeForm.value)">
                <mat-form-field class="example-full-width">
                <mat-label>Your email</mat-label>
                <input type="text" formControlName="email" matInput>
                <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                </mat-form-field>
                <button class="action" type="submit">Enter</button>
            </form>
            <span class="copyright" *ngIf="localAppService?.mobile">© {{year}}, LIVE LOVE</span>
        </div>

        <!-- <div class="footer-column social-buttons" *ngIf="!localAppService?.mobile">
            <div class="content">
                <span class="title">the live love app</span>
                <span class="text">A guide to Lebanon’s most beautiful wonders.</span>
                <div class="app-buttons-container flex-container space-between">
                    <a class="button-download">
                        <img src="https://storage.googleapis.com/livelove-dev.appspot.com/android-btn.png" alt="android">
                    </a>
                    <a class="button-download">
                        <img src="https://storage.googleapis.com/livelove-dev.appspot.com/ios-btn.png" alt="ios">
                    </a>
                </div>
            </div>
        </div> -->
        <div class="footer-column flex-container" *ngIf="!localAppService?.mobile">
            <div class="sub-column">
                <div class="content">
                    <ng-container *ngFor = "let elt of footerContent?.footerMenu">
                        <a class="menu-item" *ngIf="elt.link.indexOf('https') == -1" [routerLink]="elt.link"  >{{elt.label}}</a>
                        <a class="menu-item" *ngIf="elt.link.indexOf('https') > -1" [href]="elt.link" target="_blank"  >{{elt.label}}</a>

                    </ng-container>
                    <span class="copyright">© {{year}}, LIVE LOVE</span>
                </div>
            </div>
            <div class="sub-column">
                <mat-icon matSuffix (click)="scrollTop()">keyboard_arrow_up</mat-icon>
            </div>
            
        </div>
    </div>
</div>