import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent implements OnInit {
  title = 'liveLoveWeb';
  @ViewChild('sidenav') sidenav: MatSidenav;
  closeMenu: boolean = true;
  headerMenu: any[];
  localAppService: AppService;
  profileDropdown: any[] = [
    { label: 'View profile', link: '/profile' },
    { label: 'change password', link: '/profile/reset' },
    { label: 'logout', link: '/' },
  ];
  localAuthService: AuthService;
  fullUrl: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.fullUrl = window.location.href;
    this.authService.db
      .collection('static-content')
      .doc(this.appService.country.value)
      .collection('static-content')
      .doc('header')
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.headerMenu = doc.data().data;
        }
      });
    this.localAuthService = this.authService;
    this.localAppService = this.appService;
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log(window.location.pathname);
        this.fullUrl = window.location.href;
        this.appService.whiteHeader =
          ((window.location.pathname == '/volunteers' ||
            window.location.pathname == '/funds') &&
            this.appService.mobile) ||
          window.location.pathname != '/'
            ? true
            : false;
        this.appService.greenHeader =
          window.location.pathname != '/' &&
          window.location.pathname != '/volunteers' &&
          window.location.pathname != '/funds' &&
          this.appService.mobile
            ? true
            : false;
        console.log(this.appService.greenHeader);
      }
    });
    this.appService.whiteHeader =
      ((window.location.pathname == '/volunteers' ||
        window.location.pathname == '/funds') &&
        this.appService.mobile) ||
      window.location.pathname != '/'
        ? true
        : false;
    this.appService.greenHeader =
      window.location.pathname != '/' &&
      window.location.pathname != '/volunteers' &&
      window.location.pathname != '/funds' &&
      this.appService.mobile
        ? true
        : false;
    console.log(this.appService.greenHeader);
  }

  openMobileMenu() {
    if (
      document
        .getElementsByTagName('body')[0]
        .classList.contains('open-mobile-menu')
    ) {
      (<HTMLElement>document.getElementsByTagName('body')[0]).classList.remove(
        'open-mobile-menu'
      );
      this.closeMenu = true;
    } else {
      (<HTMLElement>document.getElementsByTagName('body')[0]).classList.add(
        'open-mobile-menu'
      );
      this.closeMenu = false;
    }
  }

  navigateTo(event) {
    if (event.value.link.indexOf('http') > -1) {
      window.open(event.value.link, '_blank');
    } else {
      this.router.navigate([event.value.link]);
    }
    this.sidenav.toggle();
    this.openMobileMenu();
  }

  logout() {
    this.authService.logout();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.appService.mobile =
      document.documentElement.clientWidth > 768 ? false : true;
    console.log(this.appService.mobile);
  }
}
