import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataPostService {
  apiUrl: string = 'https://us-central1-livelove-dev.cloudfunctions.net/'; // 'http://localhost:5001/livelove-dev/us-central1/'; //

  constructor(private http: HttpClient) {}

  sendDonations(body) {
    return this.http
      .post<any>(this.apiUrl + 'fund/donate/makeDonation', body)
      .pipe(
        map((data) => {
          console.log(data);
          if (data.approvalUrl) {
            window.location.href = data.approvalUrl[0];
          }
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  cancelRecurringDonation(body) {
    return this.http.post<any>(
      this.apiUrl + 'fund/donate/cancelDonation',
      body
    );
  }

  createFund(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'fund/submitFund', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  createJobApplication(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'gigs/submitJobPost', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  submitJobApplication(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'gigs/submitJobApplication', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  createCampaign(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'fund/submitChampionCause', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  createCommunity(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'communities/submitCommunity', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  createEvent(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'volunteers/submitEvent', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  joinEvent(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'volunteers/joinEvent', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  joinCommunity(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(this.apiUrl + 'communities/joinCommunity', body, httpOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  cancelEvent(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
    };
    return this.http
      .post<any>(
        this.apiUrl + 'volunteers/cancelEventRegistration',
        body,
        httpOptions
      )
      .pipe(
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((e) => {
          return e;
        })
      );
  }
}
