import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserResolverService implements CanActivate {
  constructor(private router : Router,
    private appService: AppService,
    private auth: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getCurrentUser().then(
        (data)=>{
          console.log(data);
          if(data){
            return true;
          }else{
            return false
          }
        }).catch(
          (e)=>{
            return false;
          }
        )
    }
  
}
