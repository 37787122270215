import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-listing-banner',
  templateUrl: './listing-banner.component.html',
  styleUrls: ['./listing-banner.component.less']
})
export class ListingBannerComponent implements OnInit {
  @Input() height:string;
  @Input() listPage: boolean;
  @Input() quickFilters: any[] = null;
  @Input() type:string;
  @Input() title:string;
  @Input() content:any;
  @Input() data:any;
  @Input() searchArray:any
  @Input() justifyContent:string = 'flex-start';
  localAppService: AppService;
  searchContainerWidth:string = '100%';
  @Output() scrollTo : EventEmitter<any> = new EventEmitter<any>();
  @Output() donateNowEvent : EventEmitter<any> = new EventEmitter<any>();
  @Output() joinEventEmitter : EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelEventEmitter : EventEmitter<any> = new EventEmitter<any>();
  localAuthService: AuthService;
  createdAt:string;

  constructor(private appService: AppService,
    private authService: AuthService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.createdAt =
      this.data && this.data.eventDate
        ? new Date(this.data.eventDate).toDateString()
        : this.data && this.data.date
        ? new Date(this.data.date).toDateString()
        : '';
    this.localAuthService = this.authService;
    this.localAppService = this.appService;
  }

  donateNow(){
    console.log('dfxgchjkhgh')
    this.donateNowEvent.emit(this.type)
  }

  joinEvent(){
    this.joinEventEmitter.emit();
  }

  cancelEvent(){
    this.cancelEventEmitter.emit()
  }

  quickFilterClick(){
    this.snackBar.open('Search will be active soon! :)', '×', { panelClass: 'success', verticalPosition: 'top', duration: 6000 });       
  }

}
