import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './main/main.component';
import { HttpClientModule } from '@angular/common/http';
import firebase from 'firebase/app';
import "firebase/firestore";
import { DataPostService } from './services/data-post.service';
import { DataCallService } from './services/data-call.service';

import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { AppService } from './services/app.service';
import { AboutComponent } from './about/about.component';
import { AuthService } from './services/auth.service';
import "node_modules/froala-editor/js/plugins/image.min.js"
import "node_modules/froala-editor/js/plugins/lists.min.js"
import "node_modules/froala-editor/js/plugins/link.min.js"
import "node_modules/froala-editor/js/plugins/video.min.js";
import { TestingComponent } from './testing/testing.component';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';

export const firebase_config = {
  apiKey: "AIzaSyC9GC8ygufXZNIzBJV1Kb-uyQ-MrgsaGew",
  authDomain: "livelove-dev.firebaseapp.com",
  projectId: "livelove-dev",
  storageBucket: "livelove-dev.appspot.com",
  messagingSenderId: "535669894096",
  appId: "1:535669894096:web:97dc4f44a512956f63e7d5",
  measurementId: "G-C6QBV4J3VP"
}
firebase.initializeApp(firebase_config);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    MainComponent,
    AboutComponent,
    TestingComponent,
    DynamicPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebase_config),
    AngularFirestoreModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule 
  ],
  providers: [DataPostService, AuthService, DataCallService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    router: Router, 
    appService: AppService,
    viewportScroller: ViewportScroller
  ) {
    router.events.pipe(
      filter((e) => {
        return e instanceof Scroll
      })
    ).subscribe((e : Scroll) => {      
      if (e.position) {
        console.log('BACK SCROLL POS', e.position);
        // backward navigation
        // appService.scrollPosition = e.position
        // viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        console.log('forwarddd')
        // appService.scrollPosition = e.position
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
 }
