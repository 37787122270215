import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { PopupComponent } from '../popup/popup.component';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent implements OnInit {
  @Input() height:string;
  @Input() image: string;
  @Input() background:string = null;
  @Input() title:string;
  @Input() text:string;
  @Input() textWeight:string;
  @Input() textFont:string;
  @Input() titleFont:string;
  @Input() textWidth:string
  @Input() buttons:any;
  @Input() color:string = "white"
  @Input() textMargin:string;
  @Input() titleMargin:string;
  @Input() buttonClass:string
  @Input() titleWidth:string
  @Input() sticker:string;
  @Input() hasVideo: false;
  @Input() boxPadding:string;
  @Input() titleAlign:string = 'center'
  @Input() alignItems:string = 'center';
  @Input() justifyContent:string = 'center';
  @Input() bigBanner:boolean = false;
  @Input() linkInTitle:any;
  @Input() blackShadow:boolean = true;
  @Input() showSeparator:boolean = false;
  localAppService:AppService;
  url: string;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router, 
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    console.log(this.image)
    this.url = window.location.pathname;
    this.localAppService = this.appService;
    // $('.noWidows').each(function(i,d){
    //   console.log(i)
    //   console.log($(d).html( $(d).text()))
    //   $(d).html( $(d).text().replace(/\s([^\s<]+)\s*$/,'\u00A0$1'))
    // });
    // $('.title').widowFix()
    console.log(this.alignItems)
  }

  openForm(formId=null){
    this.appService.openForm(formId)
  }

  navigateTo(link){
    this.router.navigate([link])
  }

  emitFunction(link=null, target=null, formId=null){
   console.log(link)
    if(link != null){
      if(link.indexOf('http') > -1){
        window.open(link, '_blank')
      } else if (link == 'openForm'){
        this.openForm(formId)
      }else if(link == 'scrollToElement'){
        this.scrollToElement(target);
      }else if(link == 'becomeAmbassador'){
        if (this.authService.loggedIn) {
          this.becomeAmbassador();
        } else {
          this.router.navigate(['/login']);
        }
      }else{
        this.router.navigate([link])
      }
    }
  }

  becomeAmbassador(){
    this.snackBar.open('Please check your email to start your training', '×', { panelClass: 'success', verticalPosition: 'top', duration: 6000 });       
  }

  scrollToElement(element): void {
    console.log(element);
    let elt = document.getElementById(element);
    console.log(elt)
    elt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
