<div
  class="item-wrapper"
  [ngClass]="{
    'box-shadow': boxShadow,
    community: id == 'communities' && sliderClass != 'recruit-slider'
  }"
  (click)="navigateTo(link, data?.objectID ? data?.objectID : data?.id)"
>
  <app-image
    *ngIf="
      id != 'gigs' && (data?.cause || data?.image || data?.icon || data?.urlKey)
    "
    [id]="sliderClass == 'recruit-slider' ? 'events' : id"
    [width]="data?.width"
    [isLogoImage]="sliderClass == 'logo-slider' ? true : false"
    [ratio]="data?.ratio"
    [url]="
      id == 'communities'
        ? data?.urlKey
        : data?.cause && data?.cause.image
        ? data?.cause.image
        : data?.image
    "
  ></app-image>
  <div
    class="details-sticky"
    *ngIf="id == 'communities' && sliderClass != 'recruit-slider'"
  >
    <span class="logo-container">
      <img
        class="logo"
        src="https://storage.googleapis.com/livelove-dev.appspot.com/liveloveLogo.png"
      />
    </span>
    <span class="description" *ngIf="data.title">{{ data.title }}</span>
    <span class="members" *ngIf="data.members && data.members.length > 0"
      >{{ data.members.length }}
      {{ data.members.length > 1 ? "Members" : "Member" }}</span
    >
    <!-- <span class="started" *ngIf="data.started">{{data.started}}</span> -->
  </div>
  <div class="details-sticky-grid" *ngIf="id == 'grid'">
    <span class="name" *ngIf="data?.name">{{ data.name }}</span>
    <span class="description" *ngIf="data?.description">{{
      data.description
    }}</span>
  </div>
  <!-- <div class="details-container" *ngIf="id == 'shop-slider'">
        <div class="details-text">
            <span class="description" *ngIf="data?.name">{{data?.name}}</span>
            <span class="action" *ngIf="data.price">{{data.price}}</span>
        </div>
    </div> -->
  <div
    class="details-container"
    *ngIf="id == 'communities' && sliderClass == 'recruit-slider'"
  >
    <div class="details-text recruit-text">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <!-- <span class="subdetails" *ngIf="data?.subtitle">{{data?.subtitle}}</span>
            <span class="description" *ngIf="data?.description">{{data?.description}}</span> -->
      <span
        class="description"
        *ngIf="data?.positions && data?.positions.length > 0"
        >{{ data?.positions.length }}
        {{ data?.positions.length > 1 ? "positions" : "position" }} open</span
      >
      <button
        type="button"
        class="liveLoveBtn"
        (click)="navigateTo('/communities/all', data?.objectID)"
        [ngStyle]="{ 'padding-left': '25px', margin: '17px 0 0 0' }"
      >
        know more
      </button>
    </div>
  </div>

  <div class="details-container" *ngIf="id == 'badges-slider'">
    <div class="details-text badges-text">
      <span class="title" *ngIf="data?.badge">{{ data?.badge }}</span>
    </div>
  </div>

  <div class="details-container" *ngIf="id == 'training'">
    <div class="details-text">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <span class="subdetails" *ngIf="data?.hour">{{ data?.hour }}</span>
      <span class="description" *ngIf="data?.description">{{
        data?.description
      }}</span>
      <button
        type="button"
        class="liveLoveBtn"
        *ngIf="buttonLabel"
        [ngStyle]="{
          'padding-left': data.completed ? '40px' : '25px',
          margin: '15px 0 0 0'
        }"
      >
        <span class="tick-icon" *ngIf="data.completed">
          <img
            src="https://storage.googleapis.com/livelove-dev.appspot.com/tick.png"
            alt="tick"
          />
        </span>
        {{ buttonLabel }}
      </button>
    </div>
  </div>

  <div class="details-container" *ngIf="id == 'campaigns'">
    <div class="details-text">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
    </div>
    <div class="champion-item">
      <div class="progress-container">
        <mat-progress-bar
          mode="determinate"
          [value]="progress"
        ></mat-progress-bar>
        <span class="donors"
          >{{ data.donors ? data.donors : 0 }} friends became donors</span
        >
        <span class="goal">{{ data.goal }} people goal</span>
      </div>
      <span class="separator" *ngIf="data?.firstName"></span>
      <div class="championed-by" *ngIf="data?.firstName">
        <!-- <div class="profile-image"></div> -->
        <div class="name">Championed by: {{ data?.firstName }}</div>
      </div>
    </div>
  </div>
  <div class="details-container" *ngIf="id == 'events'">
    <div class="details-text">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <span class="subdetails" *ngIf="data?.eventDate">{{
        data?.eventDate
      }}</span>
      <span class="description" *ngIf="data?.description">{{
        data?.description
      }}</span>
      <div
        class="flex-container volunteering-details space-between"
        *ngIf="!data?.completed"
      >
        <div class="icon-container">
          <img
            src="https://storage.googleapis.com/livelove-dev.appspot.com/volunteer-icon-7.png"
            alt="'volunteer-icon'"
          />
        </div>
        <div class="volunteers-details">
          <div class="needed">
            <span class="highlight">{{ data?.volunteersNeeded }}</span>
            <span class="text">Volunteers needed</span>
          </div>
          <div class="needed">
            <span class="highlight">{{ data?.volunteersSigned }}</span>
            <span class="text">Volunteers signed up</span>
          </div>
        </div>
      </div>
      <div
        class="flex-container completed-volunteer space-between"
        *ngIf="data?.completed && data?.volunteerEventData"
      >
        <div class="item" *ngFor="let elt of data?.volunteerEventData">
          <span class="icon" *ngIf="elt?.icon.length">
            <img [src]="elt.icon" [alt]="elt.icon" />
          </span>
          <span class="number">{{ elt.number }}</span>
          <span class="text">{{ elt.text }}</span>
        </div>
      </div>
      <button
        type="button"
        [ngClass]="{
          liveLoveBtn: !data?.completed,
          'blue-button': data?.completed
        }"
        (click)="navigateTo('/volunteers/events', data?.objectID)"
      >
        {{ data?.completed ? "View event report" : "Join now" }}
      </button>
    </div>
  </div>
  <div class="details-container volunteer-gig" *ngIf="id == 'gigs'">
    <span class="createdAt">{{ data?.date }}</span>
    <div class="details-text gigs">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <div class="gig-details flex-container">
        <span class="gig-info location" [ngStyle]="{ 'font-weight': '700' }">
          <span class="location-icon">
            <img
              src="https://storage.googleapis.com/livelove-dev.appspot.com/location-icon.png"
              alt="location"
            />
          </span>
          {{ data?.address }}</span
        >
        <a class="gig-info" (click)="openNgoListing($event, data?.userId)"
          >Posted by {{ data?.ngoName }}</a
        >
        <span class="gig-info" [ngStyle]="{ 'text-decoration': 'underline' }"
          >{{ data?.hour }} Job</span
        >
      </div>
      <span class="description" *ngIf="data?.description">{{
        data?.description
      }}</span>

      <button
        type="button"
        class="liveLoveBtn"
        [ngStyle]="{ 'margin-top': '15px' }"
        (click)="
          navigateTo(
            '/volunteers/gigs',
            data.objectID ? data?.objectID : data?.id
          )
        "
      >
        Apply now
      </button>
    </div>
  </div>
  <div class="details-container" *ngIf="id == 'funds'">
    <div class="details-text">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <div class="amount-container">
        <span class="amount"
          >${{
            data?.amountDonated
              ? ((+data.amountDonated).toFixed(0) | thousandFormat)
              : 0
          }}
          of
        </span>
        <span
          class="amount"
          [ngClass]="{ completed: data?.completed }"
          *ngIf="data?.amount"
          >${{ (+data?.amount).toFixed(0) | thousandFormat }}</span
        >
        <div>
          <span class="month">{{
            data?.duration == "monthly"
              ? " raised " + data?.duration
              : "expires on " + fundExpires
          }}</span>
        </div>
      </div>
      <span class="subdetails">From: {{ data?.donors }} Donors</span>
      <span
        class="description"
        *ngIf="data?.description"
        [ngStyle]="{ 'min-height': '140px' }"
        >{{ data?.description }}</span
      >
    </div>
  </div>
  <div class="details-container" *ngIf="sliderClass == 'shop-slider'">
    <div class="details-text shop">
      <span class="title" *ngIf="data?.title">{{ data?.title }}</span>
      <span class="subdetails" *ngIf="data?.price">{{ data?.price }}</span>
    </div>
  </div>
</div>
