import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})

export class NumbersFormatPipe implements PipeTransform {
    transform(x):String{
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");      
    }
}
