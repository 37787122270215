import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from 'src/app/funds/fund-details/fund-details.component';
import { AuthService } from 'src/app/services/auth.service';
import { DataPostService } from 'src/app/services/data-post.service';
declare let Rebilly;

@Component({
  selector: 'app-rebilly-form',
  templateUrl: './rebilly-form.component.html',
  styleUrls: ['./rebilly-form.component.less'],
})
export class RebillyFormComponent implements OnInit {
  firstName: string;
  lastName: string;
  email: string;
  buttonLabel: string = 'donate';
  disabledButton: boolean = false;
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<RebillyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private dataPostService: DataPostService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (this.authService.loggedIn) {
      this.firstName = this.authService.user.firstName;
      this.lastName = this.authService.user.lastName;
      this.email = this.authService.user.email;
    }
    // var form = document.querySelector('form');
    // form.addEventListener('submit', function (event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   // Rebilly.createToken(form)
    //   //   .then(function (token) {
    //   //     console.log(token, 'tokennnn')
    //   //     // we have a token field in the form
    //   //     // thus we can submit directly
    //   //     form.submit();
    //   //   })
    //   //   .catch(function (error) {
    //   //       // see error.code and error.message
    //   //   });
    // });
  }

  ngAfterViewInit() {
    Rebilly.initialize({
      publishableKey: 'pk_live_9u0ZFCul6bj7KZL4gjw59AUZmoRhEbDBy1aAUW3',
    }); //pk_sandbox_GMZVitUobhgUBmJ-yX3IkSFB_e48B_aYqjwgj13
    Rebilly.on('ready', () => {
      // mount a combined card element on the #payment-card `<div>` in the form above
      console.log('Rebilly: ', Rebilly);
      Rebilly.card.mount('#payment-card');
      Rebilly.card.mount('#firstName');
      Rebilly.card.mount('#lastName');
    });
    var form = <HTMLFormElement>document.getElementById('rebilly-form');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
  }

  onSubmitForm() {
    const form = <HTMLFormElement>document.getElementById('rebilly-form');
    Rebilly.initialized = true;
    Rebilly.createToken(form)
      .then((token) => {
        let id = token.id;
        console.log(id);
        let body = {
          token: id,
          date: new Date(),
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.data['title'],
          email: this.email,
          country: this.data['country'],
          type: this.data['type'],
          amount: this.data['donationAmount'],
          recurring:
            this.data['donationInterval'].toLowerCase() == 'monthly'
              ? true
              : false,
        };
        console.log(body, 'body');
        if (this.data['type'] == 'fund') {
          body['fundId'] = this.data['id'];
        } else {
          body['campaignId'] = this.data['id'];
          body['fundId'] = this.data['fundId'];
        }
        if (!this.firstName || !this.lastName || !this.email) {
          this.snackBar.open(
            'Please enter your first name or last name or email!',
            'Close',
            { panelClass: 'error', verticalPosition: 'top', duration: 6000 }
          );
        } else if (!this.disabledButton) {
          this.disabledButton = true;
          this.buttonLabel = 'Donating ...';
          this.dataPostService.sendDonations(body).subscribe((data) => {
            console.log(data.error, 'dataaaaaaa');
            if (data.stack || data.error) {
              console.log('errorrrrrrrr');
              this.snackBar.open(
                'Error from the backend - The development team is on it!',
                'Close',
                {
                  panelClass: 'error',
                  verticalPosition: 'top',
                  duration: 6000,
                }
              );
              setTimeout(() => {
                this.dialogRef.close('error');
              }, 3000);
            } else {
              console.log('no errorrrr');
              if (data.status == 'approved' && data.result == 'completed') {
                this.buttonLabel = 'Donate';
                this.snackBar.open('Donation Transaction Approved!', 'Close', {
                  panelClass: 'success',
                  verticalPosition: 'top',
                  duration: 6000,
                });
                setTimeout(() => {
                  let response = {
                    status: 'success',
                    token: id,
                    date: new Date(),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    type: this.data['type'],
                    amount: this.data['donationAmount'],
                    recurring:
                      this.data['donationInterval'].toLowerCase == 'monthly'
                        ? true
                        : false,
                  };
                  console.log(response);
                  this.dialogRef.close(response);
                }, 3000);
              } else {
                this.buttonLabel = 'Donate';
                this.snackBar.open(data.status + ':' + data.message, 'Close', {
                  panelClass: 'error',
                  verticalPosition: 'top',
                  duration: 6000,
                });
                setTimeout(() => {
                  this.dialogRef.close('error');
                }, 3000);
              }
            }
            //this.router.navigate(['donation/success']) we need to create this page its very simple, we will add this with mailchimp
          });
        }
        // we have a token field in the form
        // thus we can submit directly
      })
      .catch((error) => {
        console.log(error);
        this.snackBar.open(error.message, 'Close', {
          panelClass: 'error',
          verticalPosition: 'top',
          duration: 6000,
        });
        // see error.code and error.message
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
