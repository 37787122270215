<div mat-dialog-content>
    <form id="rebilly-form" (ngSubmit)="onSubmitForm()">
        <div class="field">
            <div class="field">
                <input data-rebilly="firstName" [(ngModel)]="firstName" name="firstName" placeholder="First Name" type="text">
            </div>
            <div class="field">
                <input data-rebilly="lastName" [(ngModel)]="lastName" name="lastName" placeholder="Last Name" type="text">
            </div>
            <div class="field">
                <input data-rebilly="email" [(ngModel)]="email" name="email" placeholder="Email" type="email">
            </div>
            <div id="payment-card">
                <!-- FramePay will inject the payment card field here -->
            </div>
            <!-- Automatically inject the payment token ID
                and payment instrument details as hidden input fields -->
            <input type="hidden" data-rebilly="token" id="rebilly-token" name="rebilly-token">
            <input type="hidden" data-rebilly="payment-instrument" name="payment-instrument">
        </div>
        <button [disabled]="disabledButton">{{buttonLabel}}</button>
    </form>
</div>
    